import * as React from 'react';
import { useState, useEffect, createRef, useRef, Fragment, useCallback } from 'react';
import { Appbar, Button, Card, Checkbox, Colors, DataTable, Divider, IconButton, Modal, Paragraph, Portal, RadioButton, Snackbar, Text, TextInput, Title } from 'react-native-paper';
import { Alert, Dimensions, FlatList, Keyboard, SafeAreaView, TouchableWithoutFeedback, View, Image, KeyboardAvoidingView, Platform, TouchableOpacity, ScrollView, ImageSourcePropType, StyleSheet } from 'react-native';
//import { ScrollView } from 'react-native-virtualized-view';
import GUIManager from '../../managers/GUIManager';
import Spinner from 'react-native-loading-spinner-overlay';
import i18n from '../../utils/i18n';
import Studio_RestManager from '../../managers/Studio_RestManager';
import LoginManager from '../../managers/LoginManager';
import { OpenMapDirections } from 'react-native-navigation-directions';
import { Table, Row, Rows } from 'react-native-table-component';
import { useNavigation } from '@react-navigation/native';
import UtilsManager from '../../managers/UtilsManager';
import memoize from "lodash/memoize";
import styles from '../../css/myCss';
import AdBanner from '../generic/AdBanner';
import Dots from 'react-native-dots-pagination';
import * as MailComposer from 'expo-mail-composer';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import Generic_ConstManager from '../../managers/Generic_ConstManager';
import Studio_ConstManager from '../../managers/Studio_ConstManager';
import Tennis_ConstManager from '../../managers/Tennis_ConstManager';
import Tennis_RestManager from '../../managers/Tennis_RestManager';
import PlayerNewReservationModal from '../player/PlayerNewReservationModal';
import TennisPlayerFirstCheckModal from '../player/tennis/TennisPlayerFirstCheckModal';

const spaceImg =  require("../../assets/studio/1_space.png");
const speakersImg =  require("../../assets/studio/2_speakers.png");
const consoleImg =  require("../../assets/studio/3_console.png");
const microphoneImg =  require("../../assets/studio/4_microphone.png");
const drumsImg =  require("../../assets/studio/5_drums.png");
const bassImg =  require("../../assets/studio/6_bass.png");
const guitarImg =  require("../../assets/studio/7_guitar.png");
const guitarAmpImg =  require("../../assets/studio/8_guitar_amplifier.png");
const pianoKeyboardsImg =  require("../../assets/studio/9_piano_keyboard.png");
const bassAmpImg =  require("../../assets/studio/10_bass_amplifier.png");
const otherEquipImg =  require("../../assets/studio/11_other_equipment.png");

const screenDimensions = Dimensions.get('window');

//Alert
  //react native
    //already imported above
  //web
  import AwesomeAlert from 'react-native-awesome-alerts';

  let MyAlertReactNative;
  let MyAlertForWeb;
  if (Platform.OS === 'web') {  //WEB
    MyAlertReactNative = null;
    MyAlertForWeb = AwesomeAlert;
  } else {                      //REACT NATIVE
    MyAlertReactNative = Alert;
    MyAlertForWeb = null;
  }
//-Alert

//image gallery
  //react native
    /*import Animated, {
      interpolate,
      useAnimatedStyle,
    } from "react-native-reanimated";
    import { BlurView as _BlurView } from "expo-blur";
    const BlurView = Animated.createAnimatedComponent(_BlurView);
    import { parallaxLayout } from "../../utils/react-native-reanimated-carousel-parallax";
    import Carousel from 'react-native-reanimated-carousel';*/
    import Carousel from 'react-native-snap-carousel';
    import ImageViewer from 'react-native-image-zoom-viewer';
  //web
    import ImageGallery from "react-image-gallery";
    //sxoliazw gia mobile! -> import "react-image-gallery/styles/css/image-gallery.css";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RightColumnWeb from './RightColumnWeb';
import LeftColumnWeb from './LeftColumnWeb';
  

let MyImageGalleryForReactNative;
let MyImageGalleryForWeb;
if (Platform.OS === 'web') {  //WEB
  MyImageGalleryForReactNative = null;
  MyImageGalleryForWeb = ImageGallery;
} else {                      //REACT NATIVE
  MyImageGalleryForReactNative = Carousel;
  MyImageGalleryForWeb = null;
}
//-image gallery

const SelectedOwnerSummaryView = ({route}) => {
  const [alertForWebVisible, setAlertForWebVisible] = useState(false);
  const [alertForWebText, setAlertForWebText] = useState('');

  const [imagesForWebCarousel, setImagesForWebCarousel] = useState<Array<PhotoItem>>([]);

//////////////////////////////////
// Generic, All domains - START //
//////////////////////////////////
const navigation = useNavigation();
const flatListRef = useRef(null);

const [selectedDomainValue, setSelectedDomainValue] = useState(route.params.params.selectedDomainValue);

const [curLoggedInOwner, setCurLoggedInOwner] = useState<Owner>(LoginManager.getInstance().loggedInOwner);
const [curLoggedInMusician, setCurLoggedInMusician] = useState<Studio_Player>(LoginManager.getInstance().loggedInMusician);
const [curLoggedInTennisPlayer, setCurLoggedInTennisPlayer] = useState<Tennis_Player>(LoginManager.getInstance().loggedInTennisPlayer);

const [alreadyRunOnceOnViewLoad, setAlreadyRunOnceOnViewLoad] = useState(true);
useEffect(() => {
  console.log("SelectedOwnerSummaryView: I'm ready!");
  setIsLoading(true);
  (async () => {
    const handleWebPageReloadActuallyRun: boolean = await LoginManager.getInstance().handleWebPageReload();
    var tmpSelectedOwner: Owner;
    if(Platform.OS==='web') {
      if(handleWebPageReloadActuallyRun==true) {
        setSelectedDomainValue(sessionStorage.getItem('storedSelectedDomainValue')!);
        tmpSelectedOwner = JSON.parse(sessionStorage.getItem("storedCurrentSelectedOwner")!);
      
        setCurLoggedInOwner(LoginManager.getInstance().loggedInOwner);
        setCurLoggedInMusician(LoginManager.getInstance().loggedInMusician);
        setCurLoggedInTennisPlayer(LoginManager.getInstance().loggedInTennisPlayer);
      }
      else {
        sessionStorage.setItem('storedSelectedDomainValue', selectedDomainValue);
        tmpSelectedOwner = route.params.params.currentSelectedOwner; // GUIManager.getInstance().allOwners[GUIManager.getInstance().curSelectedOwnerIndex];
        sessionStorage.setItem('storedCurrentSelectedOwner', JSON.stringify(tmpSelectedOwner));
      }
    }
    else {
      tmpSelectedOwner = route.params.params.currentSelectedOwner;
    }
    console.log("tmpSelectedOwner: ", tmpSelectedOwner);
    setSelectedOwner(tmpSelectedOwner);

    if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
      //get studio owner extra info
      const tmpOwnerExtraInfo_Studio : Studio_OwnerExtraInfo = await Studio_RestManager.getInstance().getOwnerExtraInfo(tmpSelectedOwner.id);
      if(tmpOwnerExtraInfo_Studio!=null && tmpOwnerExtraInfo_Studio!=undefined) {
        setOwnerExtraInfo_Studio(tmpOwnerExtraInfo_Studio);
      }
      //-get studio owner extra info
    }
    else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
        //get tennis owner extra info
        const tmpOwnerExtraInfo_Tennis : Tennis_OwnerExtraInfo = await Tennis_RestManager.getInstance().getOwnerExtraInfo(tmpSelectedOwner.id);
        if(tmpOwnerExtraInfo_Tennis!=null && tmpOwnerExtraInfo_Tennis!=undefined) {
          setOwnerExtraInfo_Tennis(tmpOwnerExtraInfo_Tennis);
        }
        //-get tennis owner extra info
        //get membership packages
        const tmpMemberships : Array<Tennis_OwnerMembership> = await Tennis_RestManager.getInstance().getAllClubMembershipsOfOwner(tmpSelectedOwner.id);
        if(tmpMemberships!=null && tmpMemberships!=undefined)
          setOwnerMemberships_Tennis(tmpMemberships);
        //-get membership packages

        //examine if user is member of this club
        const tmpClubMember : Tennis_ClubMember = {
          id: -1,
          owner_id: tmpSelectedOwner.id,
          user_email: LoginManager.getInstance().username,
          member_since_date: "",
          membership_id: -1,
          membership_valid_from_date: "",
          membership_valid_to_date: "",
          user_name_provided_by_owner: "",
          user_surname_provided_by_owner: "",
          user_telephone_provided_by_owner: ""
        }
        const webServiceResponseStr = await Tennis_RestManager.getInstance().isClubMember(tmpClubMember);
        if(webServiceResponseStr == Tennis_ConstManager.getInstance().THE_USER_IS_MEMBER_OF_THIS_CLUB)
          setIAmMemberOfTheClub_Tennis(true);
        else if(webServiceResponseStr == Tennis_ConstManager.getInstance().THE_USER_IS_NOT_MEMBER_OF_THIS_CLUB)
          setIAmMemberOfTheClub_Tennis(false);
        //-examine if user is member of this club
    }
  })();
  setIsLoading(false);
}, [alreadyRunOnceOnViewLoad]);

const [currency, setCurrency] = useState("");
const [ownerPics, setOwnerPics] = useState([]);
useEffect(() => {
  console.log("useEffect -> ownerPics: ", ownerPics);
}, [ownerPics]);
const [isLoading, setIsLoading] = useState(false);

const [snackBarVisible, setSnackBarVisible] = useState(false);
useEffect(() => {
  if(snackBarVisible == false) {
    setSnackBarText("");
  }
}, [snackBarVisible]);
const onDismissSnackBar = () => setSnackBarVisible(false);
const [snackBarText, setSnackBarText] = useState<string>('');
useEffect(() => {
  console.log(" use effect - START - snackBarText:", snackBarText);
  if(snackBarText != '') {
    setIsLoading(false);
    if(snackBarText == Tennis_ConstManager.getInstance().MAKE_RESERVATION_AS_GUEST)
      setVisibleCalendarModal(true); //continue as usual
    else {
      hideTennisFirstCheckModal(); //if visible
      setSnackBarVisible(true);
    }
  }
  console.log(" use effect - END - snackBarText:");
}, [snackBarText]);
  
const [selectedOwner, setSelectedOwner] = useState<Owner>({
  id: -1, name: '', surname: '', telephone: '', email: '', password: '', company_name: '', postal_address: '', city: '', country: '', 
  longitude: -1, latitude: -1, website: '', last_login: '', language: '', email_notifications: false, registration_date: '',
  verified: false,    //verified is when he succesfully adds the verification code sent by HandyBooker
  active: false,      //when active==false, this means that I manually created the user
  info_source: ''
});
useEffect(() => {
  if(selectedOwner.id != -1)
    getOwnersPics();           
}, [selectedOwner]);

const [visibleCalendarModal, setVisibleCalendarModal] = useState(false);

const showCalendarModal = async () => {
  setMakeReservationBtnDisabled(true);

  //first check if its the logged in user is the selected owner
  var continueAsUsual : boolean = true;
  
  if(selectedDomainValue==LoginManager.getInstance().TENNIS) {
    if(LoginManager.getInstance().loggedInOwner.id == selectedOwner.id)
      continueAsUsual = true;
    else if(iAmMemberOfTheClub_Tennis==false) {
      //get extraInfo
      if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false) {
        continueAsUsual = false;
        setCurReservationType_Tennis(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_TO_MEMBERS_ONLY);
        //alert("mono melh epitrepontai - deikse koympi SEND REQUEST");
      }
      else if(ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true) {
        continueAsUsual = false;
        setCurReservationType_Tennis(Tennis_ConstManager.getInstance().PRIVATECLUB_RESERVATIONS_ALLOWED_FOR_MEMBERS_AND_NON_MEMBERS);  
        //alert("both melh kai non-melh epitrepontai - deikse koympi SEND REQUEST kai RESERVE AS GUEST");
      }
      else if(ownerExtraInfo_Tennis.allows_reservations_to_members==false && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true) {
        continueAsUsual = true;
        setCurReservationType_Tennis(Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE);  
        //alert("epitrepontai oloi! Synexise as usual!");
      }
    }
  }

  if(continueAsUsual == true)
    setVisibleCalendarModal(true);
}
const hideCalendarModal = () => {
  setVisibleCalendarModal(false);
  setMakeReservationBtnDisabled(false);
};

const [visibleNewReservationDetailsModal, setVisibleNewReservationDetailsModal] = useState(false);

const hideNewReservationDetailsModal = () => setVisibleNewReservationDetailsModal(false);

const [allHoursOfSelectedDay, setAllHoursOfSelectedDay] = useState<Array<string>>([]);
const [allHoursOfSelectedDayItemsWithUniqueId, setAllHoursOfSelectedItemsWithUniqueId] = useState<Array<TimeslotWithID>>([]);
let id = 0; //I need a unique identifier, which remains unique even if the list is reorder/modified
useEffect(() => {
  const tmpItemsWithUniqueId = allHoursOfSelectedDay.map(tmpHour => ({
    id: id++,
    hour: tmpHour,
  }));
  setAllHoursOfSelectedItemsWithUniqueId(tmpItemsWithUniqueId);
}, [allHoursOfSelectedDay]);

function showDirections() {
  const startPoint = {
    longitude: GUIManager.getInstance().myLongitude,
    latitude: GUIManager.getInstance().myLatitude
  } 
  const endPoint = {
    longitude: selectedOwner.longitude,
    latitude: selectedOwner.latitude
  }
  const transportPlan = 'w';

  OpenMapDirections(startPoint, endPoint, transportPlan).then(res => {
    //console.log(res)
  });
}

const sendEmail = useCallback(async () => {
  let result = await MailComposer.composeAsync({
    recipients: [selectedOwner.email],
    subject: '',
    body: '',
  });
  //alert(result.status);
}, [selectedOwner.email]);

const handleOpenWebpageButtonAsync = async () => {
  if(selectedOwner.website.length > 3)
  {
    let result = await WebBrowser.openBrowserAsync(selectedOwner.website);
  }
};

const makePhoneCall = () => {
  Linking.openURL("tel://" + selectedOwner.telephone);
}

//image gallery
const [currentImageIndex, setImageIndex] = useState(0);
const [isVisible, setIsVisible] = useState(false);

const onSelect = (images, index) => {
  setImageIndex(index);
  setOwnerPics(images);
  setIsVisible(true);
};

const onRequestClose = () => setIsVisible(false);
const onLongPress = (image) => {
  //do nothing
};  

const [currentFlatListPageIndex, setCurrentFlatListPageIndex] = useState<number>(0);
useEffect(() => {
  //console.log("setCurrentFlatListPageIndex(" + currentFlatListPageIndex + ")");
  if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
    if(visibleCalendarModal == false
        && visibleNewReservationDetailsModal == false
        && currentFlatListPageIndex != curSelectedRoomIndexInRoomsArray_Studio) {
      setCurSelectedRoomIndexInRoomsArray_Studio(currentFlatListPageIndex);
    }
  }
  else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
    if(visibleCalendarModal == false
        && visibleNewReservationDetailsModal == false
        && currentFlatListPageIndex != curSelectedCourtIndexInCourtsArray_Tennis) {
      setCurSelectedCourtIndexInCourtsArray_Tennis(currentFlatListPageIndex);
    }
  }
}, [currentFlatListPageIndex]);


// Get the current date
const currentDate = new Date();
// Set the minimum date to the current date
const myMinDate = currentDate.toISOString().split('T')[0];
// Set the maximum date to 12 months from now
const myMaxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 12, currentDate.getDate()).toISOString().split('T')[0];

async function promoteHandyBookerToOwner() {
  setIsLoading(true);
  if(selectedDomainValue == LoginManager.getInstance().STUDIO) {
    const webServiceResponseStr : string | void = await Studio_RestManager.getInstance().promoteHandyBookerToOwner(selectedOwner.id);
    if(webServiceResponseStr == Studio_ConstManager.getInstance().YOU_HAVE_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_SUCCESSFULLY_MSG) {
      setSnackBarText(i18n.t('promotionSuccessful'));
    }
    else if(webServiceResponseStr == Studio_ConstManager.getInstance().YOU_HAVE_ALREADY_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_MSG) {
      setSnackBarText(i18n.t('youHaveAlreadyPromotedThisOwner'));
    }
    else {
      setSnackBarText(webServiceResponseStr);
    }
  }
  else if(selectedDomainValue == LoginManager.getInstance().TENNIS) {
    const webServiceResponseStr : string | void = await Tennis_RestManager.getInstance().promoteHandyBookerToOwner(selectedOwner.id);
    if(webServiceResponseStr == Tennis_ConstManager.getInstance().YOU_HAVE_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_SUCCESSFULLY_MSG) {
      setSnackBarText(i18n.t('promotionSuccessful'));
    }
    else if(webServiceResponseStr == Tennis_ConstManager.getInstance().YOU_HAVE_ALREADY_PROMOTED_HANDYBOOKER_TO_THIS_OWNER_MSG) {
      setSnackBarText(i18n.t('youHaveAlreadyPromotedThisOwner'));
    }
    else {
      setSnackBarText(webServiceResponseStr);
    }
  }
}

const [reservationCompletionMsg, setReservationCompletionMsg] = useState("");
useEffect(() => {
  console.log("useEffect - START - reservationCompletionMsg");
  console.log("step1: useEffect [reservationCompletionMsg]: ", reservationCompletionMsg);
  if(reservationCompletionMsg != "") {
    console.log("    reservationCompletionMsg: ", reservationCompletionMsg);
    ////GUIManager.getInstance().reservationsOfLoggedInMusicianNeedToBeRetrievedAgain = true;
    hideTennisFirstCheckModal();
    hideNewReservationDetailsModal();
    hideCalendarModal();
    //setSnackBarText(reservationCompletionMsg);
    setMakeReservationBtnDisabled(false);
    setReservationCompletionMsg("");  
    if(MyAlertReactNative != null)
      alert(reservationCompletionMsg);
    else if(MyAlertForWeb != null) {
      setAlertForWebText(reservationCompletionMsg);
      setAlertForWebVisible(true);
    }
  }
  console.log("useEffect - STOP - reservationCompletionMsg");
}, [reservationCompletionMsg]);

//get all owners pics
async function getOwnersPics() {
  setIsLoading(true);
  let tmpAllOwnerPics : Array<PicBase64> = [];
  if(selectedDomainValue == LoginManager.getInstance().STUDIO) 
    tmpAllOwnerPics = await Studio_RestManager.getInstance().getOwnerPics(selectedOwner.id);
  else if(selectedDomainValue == LoginManager.getInstance().TENNIS) 
    tmpAllOwnerPics = await Tennis_RestManager.getInstance().getOwnerPics(selectedOwner.id);
  //alert(responseJson);
  GUIManager.getInstance().allPicsOfCurOwner = tmpAllOwnerPics;    
  const tmpPhotosURLsArray = GUIManager.getInstance().allPicsOfCurOwner.map(tmpPhoto => {
    return tmpPhoto.pic_url
  })
  console.log("tmpPhotosURLsArray: ", tmpPhotosURLsArray);
  setAllPhotosURLs(tmpPhotosURLsArray);
}
//-get all owners pics

const [allPhotosURLs, setAllPhotosURLs] = useState([] as any);
useEffect(() => {
  (async () => {
    var tmpPhotosDimensions = [];
    if(allPhotosURLs!=undefined) {
      if(allPhotosURLs.length > 0) {
        for(var i=0; i<allPhotosURLs.length; i++) {
          var tmpDimensions = { width: 50, height: 50};
          if(MyImageGalleryForWeb!=null && MyImageGalleryForReactNative==null) 
            tmpDimensions = {width: 10, height: 10}; //dummy karfwto (to krataw giati kanei trigger to setOwnerPics) //await UtilsManager.getInstance().getImageDimensions(allPhotosURLs[i]);
          console.log(allPhotosURLs[i] + " dimensions: " + tmpDimensions);
          tmpPhotosDimensions[i] = {
            width: tmpDimensions.width,
            height: tmpDimensions.height
          };
        }   
      }
    }
    setAllPhotosDimensions(tmpPhotosDimensions);
  })();
}, [allPhotosURLs]);

const [allPhotosDimensions, setAllPhotosDimensions] = useState<{ width: number; height: number; }[]>([]);
useEffect(() => {
  //complete photos array
  var tmpPhotosArray = [];
  if(allPhotosURLs!=undefined && allPhotosDimensions!=undefined) {
    if(allPhotosURLs.length>0 && allPhotosDimensions.length>0) {
      for(var i=0; i<allPhotosURLs.length; i++) {
        if(MyImageGalleryForReactNative!=null && MyImageGalleryForWeb==null)
          tmpPhotosArray[i] = {
            url: allPhotosURLs[i],
            description: GUIManager.getInstance().allPicsOfCurOwner[i].pic_description!=undefined ? GUIManager.getInstance().allPicsOfCurOwner[i].pic_description : ""
          };
        else if(MyImageGalleryForWeb!=null && MyImageGalleryForReactNative==null) {
          const tmpImageScale = UtilsManager.getInstance().calculateImageScale(Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, 400, allPhotosDimensions[i].width, allPhotosDimensions[i].height, 5);
          tmpPhotosArray[i] = {
            description: GUIManager.getInstance().allPicsOfCurOwner[i].pic_description!=undefined ? "(" +  (i+1) + "/" + allPhotosURLs.length + ") " + GUIManager.getInstance().allPicsOfCurOwner[i].pic_description : "(" +  (i+1) + "/" + allPhotosURLs.length + ") ",
            thumbnail: allPhotosURLs[i],
            original: allPhotosURLs[i],
            /*width: allPhotosDimensions[i].width,
            height: allPhotosDimensions[i].height,
            initialScale: tmpImageScale.initialScale,
            maxScale: tmpImageScale.maxScale*/
          };
        }
      }
    }
  }
  setOwnerPics(tmpPhotosArray);
  console.log("tmpPhotosArray / ownerPics: ", tmpPhotosArray);
  
  if(selectedDomainValue == LoginManager.getInstance().STUDIO)
    getOwnerRooms();
  else if(selectedDomainValue == LoginManager.getInstance().TENNIS)
    getOwnerCourts();
}, [allPhotosDimensions]);  

  const [makeReservationBtnDisabled, setMakeReservationBtnDisabled] = useState(false);
////////////////////////////////
// Generic, All domains - END //
////////////////////////////////

///////////////////////////
// Studio domain - START //
///////////////////////////
const [ownerExtraInfo_Studio, setOwnerExtraInfo_Studio] = useState<Studio_OwnerExtraInfo>({
  id: -1,
  owner_id: -1,
  allows_rehearsal: false,
  allows_recording: false
});

//get all owner rooms
async function getOwnerRooms() {
  const tmpAllOwnerRooms : Array<Studio_Room> = await Studio_RestManager.getInstance().getAllRoomsOfOwner(selectedOwner.id);
  setAllRoomsOfCurOwner_Studio(tmpAllOwnerRooms);
  setIsLoading(false);
}
//-get all owner rooms  

const [allRoomsOfCurOwner_Studio, setAllRoomsOfCurOwner_Studio] = useState<Array<Studio_Room>>([]);
const [allReservationsOfThisRoom_Studio, setAllReservationsOfThisRoom_Studio] = useState<Array<Studio_Reservation>>([]);

const [selectedRoomName_Studio, setSelectedRoomName_Studio] = useState('');  

const [tableHead_Studio, setTableHead_Studio] = useState([i18n.t('day'), i18n.t('workingHours'), i18n.t('rehearsalCost'), i18n.t('recordingCost')]);
const [tableData_Studio, setTableData_Studio] = useState([[
      [i18n.t('monday'), ' ', ' ', ' '],
      [i18n.t('tuesday'), ' ', ' ', ' '],
      [i18n.t('wednesday'), ' ', ' ', ' '],
      [i18n.t('thursday'), ' ', ' ', ' '],
      [i18n.t('friday'), ' ', ' ', ' '],
      [i18n.t('saturday'), ' ', ' ', ' '],
      [i18n.t('sunday'), ' ', ' ', ' ']
    ]]);
  
const [roomName, setRoomName] = useState('');
const [roomEquipSpace, setRoomEquipSpace] = useState('');
const [roomEquipSpeakers, setRoomEquipSpeakers] = useState('');
const [roomEquipConsole, setRoomEquipConsole] = useState('');
const [roomEquipMicrophones, setRoomEquipMicrophones] = useState('');
const [roomEquipDrums, setRoomEquipDrums] = useState('');
const [roomEquipGuitars, setRoomEquipGuitars] = useState('');
const [roomEquipBasses, setRoomEquipBasses] = useState('');
const [roomEquipPianosKeyboards, setRoomEquipPianosKeyboards] = useState('');
const [roomEquipGuitarAmplifiers, setRoomEquipGuitarAmplifiers] = useState('');
const [roomEquipBassAmplifiers, setRoomEquipBassAmplifiers] = useState('');
const [roomEquipOtherEquipment, setRoomEquipOtherEquipment] = useState('');

const [curSelectedRoomIndexInRoomsArray_Studio, setCurSelectedRoomIndexInRoomsArray_Studio] = useState(0);
useEffect(() => {
  //console.log("setCurSelectedRoomIndexInRoomsArray_Studio(" + curSelectedRoomIndexInRoomsArray_Studio + ")");
  (async () => {
    const tmpCurRoom : Studio_Room = allRoomsOfCurOwner_Studio[curSelectedRoomIndexInRoomsArray_Studio];
    if(tmpCurRoom != undefined) {
      setIsLoading(true);
      const tmpAllReservationsOfTheRoom : Array<Studio_Reservation> = await Studio_RestManager.getInstance().getAllReservationsByRoomID(tmpCurRoom.id);
      setAllReservationsOfThisRoom_Studio(tmpAllReservationsOfTheRoom);
      setIsLoading(false);

      if(visibleCalendarModal == false
        && visibleNewReservationDetailsModal == false
        && currentFlatListPageIndex != curSelectedRoomIndexInRoomsArray_Studio) {
      setCurrentFlatListPageIndex(curSelectedRoomIndexInRoomsArray_Studio);
    }
    }
  })();    
}, [curSelectedRoomIndexInRoomsArray_Studio]);

useEffect(() => {
  if(allRoomsOfCurOwner_Studio != undefined)
  {
    const tmpCurRoom = allRoomsOfCurOwner_Studio[curSelectedRoomIndexInRoomsArray_Studio] as Studio_Room;
    
    if(tmpCurRoom != undefined)
    {
      setSelectedRoomName_Studio(tmpCurRoom.name);

      var tmpCurrency: string = tmpCurRoom.currency;
      const pavlaIndex : number = tmpCurrency.indexOf(' - ');
      if(pavlaIndex != 0)
        tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

      var mondayWorkingHoursLabel = tmpCurRoom.monday_working_hours_start + '-' + tmpCurRoom.monday_working_hours_stop;
      var mondayRehearsalCostLabel = tmpCurRoom.monday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.monday_cost_per_hour_reh == 0)
        mondayRehearsalCostLabel = i18n.t('freeCost');
      var mondayRecordingCostLabel = tmpCurRoom.monday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.monday_cost_per_hour_rec == 0)
        mondayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.monday_working_hours_start==='closed' || tmpCurRoom.monday_working_hours_stop==='closed')
      {
        mondayWorkingHoursLabel = i18n.t('closed');
        mondayRehearsalCostLabel = '';
        mondayRecordingCostLabel = '';
      }

      var tuesdayWorkingHoursLabel = tmpCurRoom.tuesday_working_hours_start + '-' + tmpCurRoom.tuesday_working_hours_stop;
      var tuesdayRehearsalCostLabel = tmpCurRoom.tuesday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.tuesday_cost_per_hour_reh == 0)
        tuesdayRehearsalCostLabel = i18n.t('freeCost');
      var tuesdayRecordingCostLabel = tmpCurRoom.tuesday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.tuesday_cost_per_hour_rec == 0)
        tuesdayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.tuesday_working_hours_start==='closed' || tmpCurRoom.tuesday_working_hours_stop==='closed')
      {
        tuesdayWorkingHoursLabel = i18n.t('closed');
        tuesdayRehearsalCostLabel = '';
        tuesdayRecordingCostLabel = '';
      }

      var wednesdayWorkingHoursLabel = tmpCurRoom.wednesday_working_hours_start + '-' + tmpCurRoom.wednesday_working_hours_stop;
      var wednesdayRehearsalCostLabel = tmpCurRoom.wednesday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.wednesday_cost_per_hour_reh == 0)
        wednesdayRehearsalCostLabel = i18n.t('freeCost');
      var wednesdayRecordingCostLabel = tmpCurRoom.wednesday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.wednesday_cost_per_hour_rec == 0)
        wednesdayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.wednesday_working_hours_start==='closed' || tmpCurRoom.wednesday_working_hours_stop==='closed')
      {
        wednesdayWorkingHoursLabel = i18n.t('closed');
        wednesdayRehearsalCostLabel = '';
        wednesdayRecordingCostLabel = '';
      }

      var thursdayWorkingHoursLabel = tmpCurRoom.thursday_working_hours_start + '-' + tmpCurRoom.thursday_working_hours_stop;
      var thursdayRehearsalCostLabel = tmpCurRoom.thursday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.thursday_cost_per_hour_reh == 0)
        thursdayRehearsalCostLabel = i18n.t('freeCost');
      var thursdayRecordingCostLabel = tmpCurRoom.thursday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.thursday_cost_per_hour_rec == 0)
        thursdayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.thursday_working_hours_start==='closed' || tmpCurRoom.thursday_working_hours_stop==='closed')
      {
        thursdayWorkingHoursLabel = i18n.t('closed');
        thursdayRehearsalCostLabel = '';
        thursdayRecordingCostLabel = '';
      }

      var fridayWorkingHoursLabel = tmpCurRoom.friday_working_hours_start + '-' + tmpCurRoom.friday_working_hours_stop;
      var fridayRehearsalCostLabel = tmpCurRoom.friday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.friday_cost_per_hour_reh == 0)
        fridayRehearsalCostLabel = i18n.t('freeCost');
      var fridayRecordingCostLabel = tmpCurRoom.friday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.friday_cost_per_hour_rec == 0)
        fridayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.friday_working_hours_start==='closed' || tmpCurRoom.friday_working_hours_stop==='closed')
      {
        fridayWorkingHoursLabel = i18n.t('closed');
        fridayRehearsalCostLabel = '';
        fridayRecordingCostLabel = '';
      }

      var saturdayWorkingHoursLabel = tmpCurRoom.saturday_working_hours_start + '-' + tmpCurRoom.saturday_working_hours_stop;
      var saturdayRehearsalCostLabel = tmpCurRoom.saturday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.saturday_cost_per_hour_reh == 0)
        saturdayRehearsalCostLabel = i18n.t('freeCost');
      var saturdayRecordingCostLabel = tmpCurRoom.saturday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.saturday_cost_per_hour_rec == 0)
        saturdayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.saturday_working_hours_start==='closed' || tmpCurRoom.saturday_working_hours_stop==='closed')
      {
        saturdayWorkingHoursLabel = i18n.t('closed');
        saturdayRehearsalCostLabel = '';
        saturdayRecordingCostLabel = '';
      }

      var sundayWorkingHoursLabel = tmpCurRoom.sunday_working_hours_start + '-' + tmpCurRoom.sunday_working_hours_stop;
      var sundayRehearsalCostLabel = tmpCurRoom.sunday_cost_per_hour_reh + ' ' + tmpCurrency;
      if(tmpCurRoom.sunday_cost_per_hour_reh == 0)
        sundayRehearsalCostLabel = i18n.t('freeCost');
      var sundayRecordingCostLabel = tmpCurRoom.sunday_cost_per_hour_rec + ' ' + tmpCurrency;
      if(tmpCurRoom.sunday_cost_per_hour_rec == 0)
        sundayRecordingCostLabel = i18n.t('freeCost');
      if(tmpCurRoom.sunday_working_hours_start==='closed' || tmpCurRoom.sunday_working_hours_stop==='closed')
      {
        sundayWorkingHoursLabel = i18n.t('closed');
        sundayRehearsalCostLabel = '';
        sundayRecordingCostLabel = '';
      }

      let tmpTableData = [  
        [i18n.t('monday'), mondayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? mondayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? mondayRecordingCostLabel : "N/A"],
        [i18n.t('tuesday'), tuesdayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? tuesdayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? tuesdayRecordingCostLabel : "N/A"],
        [i18n.t('wednesday'), wednesdayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? wednesdayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? wednesdayRecordingCostLabel : "N/A"],
        [i18n.t('thursday'), thursdayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? thursdayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? thursdayRecordingCostLabel : "N/A"],
        [i18n.t('friday'), fridayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? fridayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? fridayRecordingCostLabel : "N/A"],
        [i18n.t('saturday'), saturdayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? saturdayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? saturdayRecordingCostLabel : "N/A"],
        [i18n.t('sunday'), sundayWorkingHoursLabel, ownerExtraInfo_Studio.allows_rehearsal==true ? sundayRehearsalCostLabel : "N/A", ownerExtraInfo_Studio.allows_recording==true ? sundayRecordingCostLabel : "N/A"]
      ]; 
      setTableData_Studio(tmpTableData);

      setRoomName(tmpCurRoom.name);
      setRoomEquipSpace(tmpCurRoom.space);
      setRoomEquipSpeakers(tmpCurRoom.speakers);
      setRoomEquipConsole(tmpCurRoom.console);
      setRoomEquipMicrophones(tmpCurRoom.microphones);
      setRoomEquipDrums(tmpCurRoom.drums);
      setRoomEquipGuitars(tmpCurRoom.guitars);
      setRoomEquipBasses(tmpCurRoom.basses);
      setRoomEquipPianosKeyboards(tmpCurRoom.pianos_keyboards);
      setRoomEquipGuitarAmplifiers(tmpCurRoom.guitar_amplifiers);
      setRoomEquipBassAmplifiers(tmpCurRoom.bass_amplifiers);
      setRoomEquipOtherEquipment(tmpCurRoom.other_equipment);
    }
  }
}, [allRoomsOfCurOwner_Studio, curSelectedRoomIndexInRoomsArray_Studio]);
/////////////////////////
// Studio domain - END //
/////////////////////////

///////////////////////////
// Tennis domain - START //
///////////////////////////
const [ownerExtraInfo_Tennis, setOwnerExtraInfo_Tennis] = useState<Tennis_OwnerExtraInfo>({
  id: -1,
  owner_id: -1,
  allows_reservations_to_members: false,
  allows_reservations_to_non_members: false
});

const [ownerMemberships_Tennis, setOwnerMemberships_Tennis] = useState<Array<Tennis_OwnerMembership>>([]);

//get all owner courts
async function getOwnerCourts() {
  const tmpAllOwnerCourts : Array<Tennis_Court> = await Tennis_RestManager.getInstance().getAllCourtsOfOwner(selectedOwner.id);
  setAllCourtsOfCurOwner_Tennis(tmpAllOwnerCourts);
  setIsLoading(false);
}
//-get all owner courts 

const [allCourtsOfCurOwner_Tennis, setAllCourtsOfCurOwner_Tennis] = useState<Array<Tennis_Court>>([]);
const [allReservationsOfThisCourt_Tennis, setAllReservationsOfThisCourt_Tennis] = useState<Array<Tennis_Reservation>>([]);

const [selectedCourtName_Tennis, setSelectedCourtName_Tennis] = useState('');  

const [tableHead_Tennis, setTableHead_Tennis] = useState([]);
useEffect(() => {
  if(ownerExtraInfo_Tennis.allows_reservations_to_members==true 
      && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false)
      setTableHead_Tennis([i18n.t('day'), i18n.t('workingHours')]);
  else
      setTableHead_Tennis([i18n.t('day'), i18n.t('workingHours'), i18n.t('cost')]);
}, [ownerExtraInfo_Tennis]);

const [tableData_Tennis, setTableData_Tennis] = useState([]);
useEffect(() => {
  if(ownerExtraInfo_Tennis.allows_reservations_to_members==true 
      && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false)
      setTableData_Tennis([[
        [i18n.t('monday'), ' '],
        [i18n.t('tuesday'), ' '],
        [i18n.t('wednesday'), ' '],
        [i18n.t('thursday'), ' '],
        [i18n.t('friday'), ' '],
        [i18n.t('saturday'), ' '],
        [i18n.t('sunday'), ' ']
      ]]);
  else
      setTableData_Tennis([[
        [i18n.t('monday'), ' ', ' '],
        [i18n.t('tuesday'), ' ', ' '],
        [i18n.t('wednesday'), ' ', ' '],
        [i18n.t('thursday'), ' ', ' '],
        [i18n.t('friday'), ' ', ' '],
        [i18n.t('saturday'), ' ', ' '],
        [i18n.t('sunday'), ' ', ' ']
      ]]);
}, [ownerExtraInfo_Tennis]);
  
const [curSelectedCourtIndexInCourtsArray_Tennis, setCurSelectedCourtIndexInCourtsArray_Tennis] = useState(0);
useEffect(() => {
  //console.log("setCurSelectedCourtIndexInCourtsArray_Tennis(" + curSelectedCourtIndexInCourtsArray_Tennis + ")");
  (async () => {
    const tmpCurCourt : Tennis_Court = allCourtsOfCurOwner_Tennis[curSelectedCourtIndexInCourtsArray_Tennis];
    if(tmpCurCourt != undefined) {
      setIsLoading(true);
      const tmpAllReservationsOfTheCourt : Array<Tennis_Reservation> = await Tennis_RestManager.getInstance().getAllReservationsByCourtID(tmpCurCourt.id);
      setAllReservationsOfThisCourt_Tennis(tmpAllReservationsOfTheCourt);
      setIsLoading(false);

      if(visibleCalendarModal == false
        && visibleNewReservationDetailsModal == false
        && currentFlatListPageIndex != curSelectedCourtIndexInCourtsArray_Tennis) {
        setCurrentFlatListPageIndex(curSelectedCourtIndexInCourtsArray_Tennis);
      }
    }
  })();    
}, [curSelectedCourtIndexInCourtsArray_Tennis]);

useEffect(() => {
  if(allCourtsOfCurOwner_Tennis != undefined)
  {
    const tmpCurCourt = allCourtsOfCurOwner_Tennis[curSelectedCourtIndexInCourtsArray_Tennis] as Tennis_Court;
    
    if(tmpCurCourt != undefined)
    {
      setSelectedCourtName_Tennis(tmpCurCourt.name);

      var tmpCurrency: string = tmpCurCourt.currency;
      setCurrency(tmpCurrency);
      const pavlaIndex : number = tmpCurrency.indexOf(' - ');
      if(pavlaIndex != 0)
        tmpCurrency = tmpCurrency.substr(0, pavlaIndex);

      var mondayWorkingHoursLabel = tmpCurCourt.monday_working_hours_start + '-' + tmpCurCourt.monday_working_hours_stop;
      var mondayCostLabel = tmpCurCourt.monday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.monday_cost_per_hour == 0)
        mondayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.monday_working_hours_start==='closed' || tmpCurCourt.monday_working_hours_stop==='closed')
      {
        mondayWorkingHoursLabel = i18n.t('closed');
        mondayCostLabel = '';
      }

      var tuesdayWorkingHoursLabel = tmpCurCourt.tuesday_working_hours_start + '-' + tmpCurCourt.tuesday_working_hours_stop;
      var tuesdayCostLabel = tmpCurCourt.tuesday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.tuesday_cost_per_hour == 0)
        tuesdayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.tuesday_working_hours_start==='closed' || tmpCurCourt.tuesday_working_hours_stop==='closed')
      {
        tuesdayWorkingHoursLabel = i18n.t('closed');
        tuesdayCostLabel = '';
      }

      var wednesdayWorkingHoursLabel = tmpCurCourt.wednesday_working_hours_start + '-' + tmpCurCourt.wednesday_working_hours_stop;
      var wednesdayCostLabel = tmpCurCourt.wednesday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.wednesday_cost_per_hour == 0)
        wednesdayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.wednesday_working_hours_start==='closed' || tmpCurCourt.wednesday_working_hours_stop==='closed')
      {
        wednesdayWorkingHoursLabel = i18n.t('closed');
        wednesdayCostLabel = '';
      }

      var thursdayWorkingHoursLabel = tmpCurCourt.thursday_working_hours_start + '-' + tmpCurCourt.thursday_working_hours_stop;
      var thursdayCostLabel = tmpCurCourt.thursday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.thursday_cost_per_hour == 0)
        thursdayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.thursday_working_hours_start==='closed' || tmpCurCourt.thursday_working_hours_stop==='closed')
      {
        thursdayWorkingHoursLabel = i18n.t('closed');
        thursdayCostLabel = '';
      }

      var fridayWorkingHoursLabel = tmpCurCourt.friday_working_hours_start + '-' + tmpCurCourt.friday_working_hours_stop;
      var fridayCostLabel = tmpCurCourt.friday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.friday_cost_per_hour == 0)
        fridayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.friday_working_hours_start==='closed' || tmpCurCourt.friday_working_hours_stop==='closed')
      {
        fridayWorkingHoursLabel = i18n.t('closed');
        fridayCostLabel = '';
      }

      var saturdayWorkingHoursLabel = tmpCurCourt.saturday_working_hours_start + '-' + tmpCurCourt.saturday_working_hours_stop;
      var saturdayCostLabel = tmpCurCourt.saturday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.saturday_cost_per_hour == 0)
        saturdayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.saturday_working_hours_start==='closed' || tmpCurCourt.saturday_working_hours_stop==='closed')
      {
        saturdayWorkingHoursLabel = i18n.t('closed');
        saturdayCostLabel = '';
      }

      var sundayWorkingHoursLabel = tmpCurCourt.sunday_working_hours_start + '-' + tmpCurCourt.sunday_working_hours_stop;
      var sundayCostLabel = tmpCurCourt.sunday_cost_per_hour + ' ' + tmpCurrency;
      if(tmpCurCourt.sunday_cost_per_hour == 0)
        sundayCostLabel = i18n.t('freeCost');
      if(tmpCurCourt.sunday_working_hours_start==='closed' || tmpCurCourt.sunday_working_hours_stop==='closed')
      {
        sundayWorkingHoursLabel = i18n.t('closed');
        sundayCostLabel = '';
      }

      let tmpTableData = [  
        [i18n.t('monday'), mondayWorkingHoursLabel, mondayCostLabel],
        [i18n.t('tuesday'), tuesdayWorkingHoursLabel, tuesdayCostLabel],
        [i18n.t('wednesday'), wednesdayWorkingHoursLabel, wednesdayCostLabel],
        [i18n.t('thursday'), thursdayWorkingHoursLabel, thursdayCostLabel],
        [i18n.t('friday'), fridayWorkingHoursLabel, fridayCostLabel],
        [i18n.t('saturday'), saturdayWorkingHoursLabel, saturdayCostLabel],
        [i18n.t('sunday'), sundayWorkingHoursLabel, sundayCostLabel]
      ]; 
      let tmpTableData_forPrivateClub = [  
        [i18n.t('monday'), mondayWorkingHoursLabel],
        [i18n.t('tuesday'), tuesdayWorkingHoursLabel],
        [i18n.t('wednesday'), wednesdayWorkingHoursLabel],
        [i18n.t('thursday'), thursdayWorkingHoursLabel],
        [i18n.t('friday'), fridayWorkingHoursLabel],
        [i18n.t('saturday'), saturdayWorkingHoursLabel],
        [i18n.t('sunday'), sundayWorkingHoursLabel]
      ]; 
      if(ownerExtraInfo_Tennis.allows_reservations_to_members==true 
          && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false) 
        setTableData_Tennis(tmpTableData_forPrivateClub);
      else
        setTableData_Tennis(tmpTableData);

      //set court type image
      if(tmpCurCourt.type == 0)
        setCurCourtTypeImg(acrylic);
      else if(tmpCurCourt.type == 1)
        setCurCourtTypeImg(artificialClay);
      else if(tmpCurCourt.type == 2)
        setCurCourtTypeImg(artificialGrass);
      else if(tmpCurCourt.type == 3)
        setCurCourtTypeImg(asphalt);
      else if(tmpCurCourt.type == 4)
        setCurCourtTypeImg(carpet);
      else if(tmpCurCourt.type == 5)
        setCurCourtTypeImg(clay);
      else if(tmpCurCourt.type == 6)
        setCurCourtTypeImg(concrete);
      else if(tmpCurCourt.type == 7)
        setCurCourtTypeImg(grass);
      else if(tmpCurCourt.type == 8)
        setCurCourtTypeImg(other);

        //set court type string
        setCurCourtTypeStr( UtilsManager.getInstance().getCourtType_Tennis(tmpCurCourt.type));
    }
  }
}, [allCourtsOfCurOwner_Tennis, curSelectedCourtIndexInCourtsArray_Tennis]);

const acrylic =  require("../../assets/tennis/0_acrylic.jpg");
const artificialClay =  require("../../assets/tennis/1_artificial_clay.jpg");
const artificialGrass =  require("../../assets/tennis/2_artificial_grass.jpg");
const asphalt =  require("../../assets/tennis/3_asphalt.jpg");
const carpet =  require("../../assets/tennis/4_carpet.jpg");
const clay =  require("../../assets/tennis/5_clay.jpg");
const concrete =  require("../../assets/tennis/6_concrete.jpg");
const grass =  require("../../assets/tennis/7_grass.jpg");
const other =  require("../../assets/tennis/8_other.jpg");

const [curCourtTypeImg, setCurCourtTypeImg] = useState(other);
const [curCourtTypeStr, setCurCourtTypeStr] = useState('');

//first check - for clubs
const [tennisVisibleFirstCheckModal, setTennisVisibleFirstCheckModal] = useState(false);
useEffect(() => {
  //init
  if(tennisVisibleFirstCheckModal == false) {
    setCurReservationType_Tennis("");
    setMakeReservationBtnDisabled(false);
  }
}, [tennisVisibleFirstCheckModal]);
const hideTennisFirstCheckModal = () => { 
  setMakeReservationBtnDisabled(false);
  setTennisVisibleFirstCheckModal(false);
}
const [curReservationType_Tennis, setCurReservationType_Tennis] = useState("");
useEffect(() => {
  console.log("useEffect - curReservationType_Tennis:", curReservationType_Tennis);
  if(curReservationType_Tennis != "") {
    /*if(curReservationType_Tennis == Tennis_ConstManager.getInstance().NONPRIVATECLUB_RESERVATIONS_ALLOWED_TO_ANYONE)
      setVisibleCalendarModal(true);
    else*/
      setTennisVisibleFirstCheckModal(true);
  }
}, [curReservationType_Tennis]);

const [iAmMemberOfTheClub_Tennis, setIAmMemberOfTheClub_Tennis] = useState<boolean>(false);
//-first check - for clubs
/////////////////////////
// Tennis domain - END //
/////////////////////////

  const [swiperIndex, setSwiperIndex] = useState(0);
  useEffect(() => {
    console.log("useEffect - swiperIndex:", swiperIndex);
  }, [swiperIndex]);

  const handleSwiperIndexChange = (index) => {
    setSwiperIndex(index);
  };

  //image gallery - react native
  const screenDimensions = Dimensions.get('window');
  const ITEM_WIDTH = screenDimensions.width * 0.7;
  const [fullScreenPicModalVisible, setFullScreenPicModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => { 
        console.log("I've pressed -> item.uri: " + item.uri);
        setFullScreenPicModalVisible(true); 
        setSelectedImage(item);
      }}>
        <Image style={{width: ITEM_WIDTH, height: ITEM_WIDTH, borderRadius: 8,}} 
          source={{ uri: item.url }} />
      </TouchableOpacity>
    );
  };

  const renderIndicator = (currentIndex, allSize) => {
    const currentImage = ownerPics[currentIndex-1];
    return (
      <View style={{ position: 'absolute', bottom: 0, width: screenDimensions.width, alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.6)', paddingBottom: 20 }}>
        <Text style={{ color: '#fff', paddingBottom: 5 }}>{currentIndex}/{allSize}</Text>
        <Text style={{ color: '#fff', paddingBottom: 5 }}>{currentImage!=undefined && currentImage.description!=undefined ? currentImage.description : ""}</Text>
      </View>
    );
  };
  //-image gallery - react native

  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  useEffect(() => {
    console.log("useEffect - scrollViewHeight:", scrollViewHeight);
  }, [scrollViewHeight]);

  const handleScrollViewLayout = (event) => {
    const height = event.nativeEvent.layout.height;
    setScrollViewHeight(height);
  };

  return (
      <View style={styles.container}>
              
          <Portal>
            <Modal visible={tennisVisibleFirstCheckModal} onDismiss={hideTennisFirstCheckModal} contentContainerStyle={styles.modalContainer}>
              <TennisPlayerFirstCheckModal
                    curOwner={selectedOwner}
                    curReservationType_Tennis={curReservationType_Tennis}
                    ownerMemberships_Tennis={ownerMemberships_Tennis}
                    currency={currency}
                    setSnackBarText={setSnackBarText}
              />
            </Modal>
          </Portal>

          <Portal>
            <Modal visible={visibleCalendarModal} onDismiss={hideCalendarModal} contentContainerStyle={styles.modalContainer}>
              <PlayerNewReservationModal
                    selectedDomainValue={selectedDomainValue}
                    curOwner={selectedOwner}
                    allRoomsOfCurOwner_Studio={allRoomsOfCurOwner_Studio}
                    ownerExtraInfo_Studio={ownerExtraInfo_Studio}
                    allCourtsOfCurOwner_Tennis={allCourtsOfCurOwner_Tennis}
                    setReservationCompletionMsg={setReservationCompletionMsg}
                    iAmMemberOfTheClub_Tennis={iAmMemberOfTheClub_Tennis}
              />
            </Modal>
          </Portal>

          <Spinner
            visible={isLoading}
            textContent={i18n.t('loading')}
            textStyle={styles.spinnerTextStyle}
          />

          <View style={{flexDirection:"row"}}>
            <LeftColumnWeb/>
            <View style={styles.columnMainApp}>

              <Appbar.Header>
                <Appbar.Content title={selectedOwner.company_name} subtitle={selectedOwner.postal_address + ', ' + selectedOwner.city+ ', ' + selectedOwner.country} />              
              </Appbar.Header>

              {selectedDomainValue==LoginManager.getInstance().TENNIS && iAmMemberOfTheClub_Tennis==true 
                && <Text style={styles.clubMemberTitle}>{i18n.t('youAreMemberOfThisTennisClub')}
              </Text>}

              <Portal>          
                <Modal style={{ position: "absolute", top: -30 }} visible={fullScreenPicModalVisible && selectedImage!=null && selectedImage!=undefined} onDismiss={() => setFullScreenPicModalVisible(false)}  contentContainerStyle={{ 
                          width: screenDimensions.width, 
                          height: screenDimensions.height, 
                          }} 
                >
                  <ImageViewer
                      style={{ margin: 0 }}
                      imageUrls={ownerPics.map(pic => ({ url: pic.url }))}
                      index={ownerPics.findIndex(pic => pic.url === selectedImage?.url)}
                      enableSwipeDown={true}
                      onSwipeDown={() => setFullScreenPicModalVisible(false)}
                      renderIndicator={renderIndicator}
                    />
                </Modal>
              </Portal>

              <ScrollView onLayout={handleScrollViewLayout}>
                <SafeAreaView style={styles.root}>
                  
                  {MyImageGalleryForReactNative!=null && ownerPics!=undefined && ownerPics.length>0 && 
                    <View style={{flex: 1,
                      backgroundColor: '#fff',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 10
                    }}>  
                      <Carousel
                        data={ownerPics}
                        renderItem={renderItem}
                        sliderWidth={screenDimensions.width}
                        itemWidth={ITEM_WIDTH}
                        inactiveSlideScale={0.9}
                        inactiveSlideOpacity={0.7}
                      />  
                    </View>
                  }

                  {MyImageGalleryForWeb!=null && ownerPics!=undefined && ownerPics.length>0 && 
                    <View style={{ backgroundColor: 'whitesmoke' }}>
                      <ImageGallery items={ownerPics} />
                    </View>
                  }

                  <DataTable>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('address')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedOwner.postal_address}, {selectedOwner.city}</DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                        <IconButton
                          icon={({ color, size }) => (<MaterialCommunityIcons name="map" color={color} size={size} />)}
                          color={Colors.blue900}
                          size={35}
                          onPress={() => { showDirections(); } } />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('telephone')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedOwner.telephone!=Generic_ConstManager.getInstance().INTRUDER_MSG ? selectedOwner.telephone : i18n.t('registrationIsNeeded')}</DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                        <IconButton
                          icon={({ color, size }) => (<MaterialCommunityIcons name="phone" color={color} size={size} />)}
                          color={Colors.blue900}
                          size={35}
                          disabled={selectedOwner.telephone==Generic_ConstManager.getInstance().INTRUDER_MSG}
                          onPress={makePhoneCall} />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={styles.tableRow}>
                      <DataTable.Title style={styles.tableTitle}>Email</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedOwner.email!=Generic_ConstManager.getInstance().INTRUDER_MSG ? selectedOwner.email : i18n.t('registrationIsNeeded')}</DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                        <IconButton
                          icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)}
                          color={Colors.blue900}
                          size={35}
                          disabled={selectedOwner.telephone==Generic_ConstManager.getInstance().INTRUDER_MSG}
                          onPress={sendEmail} />
                      </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row style={[styles.tableRow, { display: selectedOwner.website!='' ? 'flex' : 'none' }]}>
                      <DataTable.Title style={styles.tableTitle}>{i18n.t('website')}</DataTable.Title>
                      <DataTable.Cell style={styles.tableValueColumn}>{selectedOwner.website!=Generic_ConstManager.getInstance().INTRUDER_MSG ? selectedOwner.website : i18n.t('registrationIsNeeded')}</DataTable.Cell>
                      <DataTable.Cell style={styles.tableIcon}>
                        <IconButton
                          style={{ alignSelf: 'center' }}
                          icon={({ color, size }) => (<MaterialCommunityIcons name="web" color={color} size={size} />)}
                          color={Colors.blue900}
                          size={35}
                          disabled={selectedOwner.telephone==Generic_ConstManager.getInstance().INTRUDER_MSG}
                          onPress={handleOpenWebpageButtonAsync} />
                      </DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                  {selectedDomainValue==LoginManager.getInstance().STUDIO && visibleCalendarModal==false && snackBarVisible==false && <Title style={{ justifyContent: 'center', backgroundColor: Colors.blueGrey100,  display: allRoomsOfCurOwner_Studio.length>0 ? 'flex' : 'none'}}>{i18n.t('selectRoom')}</Title>}
                  {selectedDomainValue==LoginManager.getInstance().STUDIO && visibleCalendarModal==false && snackBarVisible==false && <RadioButton.Group onValueChange={newValue => setCurSelectedRoomIndexInRoomsArray_Studio(parseInt(newValue))} value={curSelectedRoomIndexInRoomsArray_Studio.toString()}>
                    {allRoomsOfCurOwner_Studio.map((room: Studio_Room, i) => (             
                        <RadioButton.Item
                        key={room.id}
                        label={room.name}
                        value={i.toString()}
                        status={curSelectedRoomIndexInRoomsArray_Studio === i ? 'checked' : 'unchecked'} />
                    ))}      
                  </RadioButton.Group>}
                  
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && selectedOwner.active==true && isLoading==false && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{i18n.t('whoCanReserve')}</Title>}
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==false && isLoading==false && <Text style={{ margin: 15, textAlign: "center"}}>{i18n.t('onlyClubMembersCanReserve')}</Text>}
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true && isLoading==false && <Text style={{ margin: 15, textAlign: "center"}}>{i18n.t('clubMembersAndNonClubMembersCanReserve')}</Text>}
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && ownerExtraInfo_Tennis.allows_reservations_to_members==false && ownerExtraInfo_Tennis.allows_reservations_to_non_members==true && isLoading==false && <Text style={{ margin: 15, textAlign: "center"}}>{i18n.t('onlyNonClubMembersCanReserve')}</Text>}
                  
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerMemberships_Tennis.length>0 && isLoading==false && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{i18n.t('membershipOptions')}</Title>}
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && ownerExtraInfo_Tennis.allows_reservations_to_members==true && ownerMemberships_Tennis.length>0 && isLoading==false && <Fragment>
                    {ownerMemberships_Tennis.map(item => {
                        return (
                          <View style={{ flexDirection: 'row' }} key={item.id}>
                            <View style={{ flex: 6 }}>
                              <View style={{ borderRadius: 5, borderColor: 'black', borderWidth: 0.5, margin: 5 }}>
                                <TextInput
                                    label={i18n.t('membershipTitle')}
                                    value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.title}
                                    style={{ flex: 1, backgroundColor: 'lightgray' }}
                                />
                                <TextInput
                                    label={i18n.t('membershipDescription')}
                                    value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.description}
                                    style={{ flex: 1 }}
                                    multiline
                                    numberOfLines={3}
                                />
                                <TextInput
                                    label={i18n.t('membershipDuration')}
                                    value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.duration}
                                    style={{ flex: 1 }}
                                />
                                <TextInput
                                    label={i18n.t('membershipCost')}
                                    value={ownerMemberships_Tennis.find(membership => membership.id === item.id)?.cost}
                                    style={{ flex: 1 }}
                                    right={<TextInput.Affix text={currency}/>}
                                />
                              </View>
                            </View>
                          </View>
                    )})}
                  </Fragment>}

                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100,  display: allCourtsOfCurOwner_Tennis.length>0 ? 'flex' : 'none'}}>{i18n.t('selectCourt')}</Title>}
                  {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && <RadioButton.Group onValueChange={newValue => setCurSelectedCourtIndexInCourtsArray_Tennis(parseInt(newValue))} value={curSelectedCourtIndexInCourtsArray_Tennis.toString()}>
                    {allCourtsOfCurOwner_Tennis.map((court: Tennis_Court, i) => (             
                        <RadioButton.Item
                        key={court.id}
                        label={court.name}
                        value={i.toString()}
                        status={curSelectedCourtIndexInCourtsArray_Tennis === i ? 'checked' : 'unchecked'} />
                    ))}      
                  </RadioButton.Group>}
                  <Divider/>
                  </SafeAreaView>
                    <View style={styles.modalMainContainer}>
                      <View style={styles.modalSubContainer}>
                        {selectedDomainValue==LoginManager.getInstance().STUDIO && visibleCalendarModal==false && snackBarVisible==false && <Dots activeDotWidth={6} activeDotHeight={6} passiveDotHeight={6}
                              passiveDotWidth={6} length={allRoomsOfCurOwner_Studio.length}
                              activeColor={"#000"} active={curSelectedRoomIndexInRoomsArray_Studio}
                        />}
                        {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && <Dots activeDotWidth={6} activeDotHeight={6} passiveDotHeight={6}
                              passiveDotWidth={6} length={allCourtsOfCurOwner_Tennis.length}
                              activeColor={"#000"} active={curSelectedCourtIndexInCourtsArray_Tennis}
                        />}
                        {selectedDomainValue==LoginManager.getInstance().STUDIO && visibleCalendarModal==false && snackBarVisible==false && <FlatList
                          ref={flatListRef}
                          scrollEnabled={false}
                          data={allRoomsOfCurOwner_Studio}
                          horizontal={true}
                          keyExtractor={(room : Studio_Room) => room.id.toString()}
                          pagingEnabled={true}
                          viewabilityConfig={{
                            itemVisiblePercentThreshold: 50
                          }}
                          initialScrollIndex={curSelectedRoomIndexInRoomsArray_Studio}
                          showsHorizontalScrollIndicator={true}
                          /*onViewableItemsChanged={({ viewableItems }) => {
                              if(visibleCalendarModal==false && visibleNewReservationDetailsModal==false) {
                                  // Find the index of the first viewable item in the data array
                                  const firstViewableItemIndex = allRoomsOfCurOwner_Studio.findIndex(
                                    item => item.id === viewableItems[0].item.id
                                  );
                                  if(firstViewableItemIndex != curSelectedRoomIndexInRoomsArray_Studio) {
                                    console.log("onViewableItemsChanged -> firstViewableItemIndex: " + firstViewableItemIndex);
                                    setCurSelectedRoomIndexInRoomsArray_Studio(firstViewableItemIndex);
                                  }
                              }
                          }}*/
                          scrollEventThrottle={16} // This value should be greater than 0
                          renderItem={({ item }) => (
                            <View style={ styles.flatlistItemContainer }>                          
                              <View key={item.id}>
                                <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{roomName} - {i18n.t('details')}</Title>
                                <Table borderStyle={{borderWidth: 2, borderColor: Colors.blueGrey100}}>
                                  <Row data={tableHead_Studio} style={styles.head} textStyle={styles.text}/>
                                  <Rows data={tableData_Studio} textStyle={styles.text}/>
                                </Table>
                                <Title style={{ justifyContent: 'center', backgroundColor: Colors.blueGrey100, 
                                                  display: item.space != '' 
                                                            || item.drums != ''
                                                            || item.guitar_amplifiers != ''
                                                            || item.bass_amplifiers != ''
                                                            || item.microphones != ''
                                                            || item.console != ''
                                                            || item.speakers != ''
                                                            || item.guitars != ''
                                                            || item.basses != ''
                                                            || item.pianos_keyboards != ''
                                                            || item.other_equipment != ''
                                                          ? 'flex' : 'none'
                                              }}>{roomName} - {i18n.t('equipment')}</Title>
                                <Divider/>
                                <View style={styles.roomEquipmentTableRow}>
                                  <View style={{ display: roomEquipSpace != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>                    
                                      <Image source={spaceImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('space')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipSpace}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipDrums != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>
                                      <Image source={drumsImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('drums')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipDrums}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipGuitarAmplifiers != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>                    
                                      <Image source={guitarAmpImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('guitarAmplifiers')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipGuitarAmplifiers}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                </View>
                                <View style={{ flexDirection:"row" }}>
                                <View style={{ display: roomEquipBassAmplifiers != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>
                                      <Image source={bassAmpImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('bassAmplifiers')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipBassAmplifiers}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipMicrophones != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>      
                                      <Image source={microphoneImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('microphones')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipMicrophones}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipConsole != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>             
                                      <Image source={consoleImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('console')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipConsole}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                </View>
                                <View style={{ flexDirection:"row" }}>
                                  <View style={{ display: roomEquipSpeakers != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>          
                                      <Image source={speakersImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('speakers')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipSpeakers}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipGuitars != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>                
                                      <Image source={guitarImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('guitars')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipGuitars}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipBasses != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>               
                                      <Image source={bassImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('basses')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipBasses}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                </View>
                                <View style={{ flexDirection:'row' }}>
                                  <View style={{ display: roomEquipPianosKeyboards != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>                
                                      <Image source={pianoKeyboardsImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('pianosKeyboards')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipPianosKeyboards}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                  <View style={{ display: roomEquipOtherEquipment != '' ? 'flex' : 'none' }}>
                                    <Card style={styles.equipmentCard}>                    
                                      <Image source={otherEquipImg} style={styles.equipmentImg} />
                                      <Card.Content>
                                        <Title style={styles.equipmentCardTitle}>{i18n.t('otherEquipment')}</Title>
                                        <Divider/>
                                        <Paragraph style={styles.equipmentCardText}>{roomEquipOtherEquipment}</Paragraph>
                                      </Card.Content>
                                    </Card>
                                  </View>
                                </View>
                              </View>
                            </View>
                        )}
                        />}
                        {selectedDomainValue==LoginManager.getInstance().TENNIS && visibleCalendarModal==false && tennisVisibleFirstCheckModal==false && snackBarVisible==false && <FlatList
                          ref={flatListRef}
                          scrollEnabled={false}
                          data={allCourtsOfCurOwner_Tennis}
                          horizontal={true}
                          keyExtractor={(court : Tennis_Court) => court.id.toString()}
                          pagingEnabled={true}
                          viewabilityConfig={{
                            itemVisiblePercentThreshold: 50
                          }}
                          initialScrollIndex={curSelectedCourtIndexInCourtsArray_Tennis}
                          showsHorizontalScrollIndicator={true}
                          scrollEventThrottle={16} // This value should be greater than 0
                          renderItem={({ item }) => (
                            <View style={ styles.flatlistItemContainer }>                          
                              <View key={item.id}>
                                <Title style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{item.name} - {i18n.t('details')}</Title>
                                {selectedDomainValue==LoginManager.getInstance().TENNIS && <Text style={{ textAlign: 'center', backgroundColor: Colors.blueGrey100 }}>{i18n.t('type')} : {curCourtTypeStr}</Text>}
                                {selectedDomainValue==LoginManager.getInstance().TENNIS && <TouchableOpacity>
                                  <Image
                                    source={curCourtTypeImg}
                                    style={{width: Platform.OS!='web' ? screenDimensions.width : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH, 
                                    height: Platform.OS!='web' ? screenDimensions.width*(150/640) : Generic_ConstManager.getInstance().WEB_MAINAPP_COLUMN_WIDTH*(150/640)}} //height/width
                                  />
                                </TouchableOpacity>}
                                <Table borderStyle={{borderWidth: 2, borderColor: Colors.blueGrey100}}>
                                  <Row data={tableHead_Tennis} style={styles.head} textStyle={styles.text}/>
                                  <Rows data={tableData_Tennis} textStyle={styles.text}/>
                                </Table>
                              </View>
                            </View>
                        )}
                        />}
                      </View>
                    </View>
                    <View style={{display: selectedOwner.active ? 'none' : 'flex'}}>
                      <Text style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 20, color: 'red' }}>{i18n.t('ownerNotYetRegistered')}</Text>
                      <Divider/>
                      <Text style={{ paddingLeft: 60, paddingRight: 60, textAlign: 'justify', marginTop: 10, marginBottom: 10 }}>{i18n.t('promoteHandyBookerToOwnerPartOne')}
                          {LoginManager.getInstance().loggedInOwner.id!=-1 && <Text style={{ fontWeight: 'bold' }}>{LoginManager.getInstance().loggedInOwner.name} {LoginManager.getInstance().loggedInOwner.surname} </Text>}
                          {LoginManager.getInstance().loggedInMusician.id!=-1 && <Text style={{ fontWeight: 'bold' }}>{LoginManager.getInstance().loggedInMusician.name} {LoginManager.getInstance().loggedInMusician.surname} </Text>}
                          {LoginManager.getInstance().loggedInTennisPlayer.id!=-1 && <Text style={{ fontWeight: 'bold' }}>{LoginManager.getInstance().loggedInTennisPlayer.name} {LoginManager.getInstance().loggedInTennisPlayer.surname} </Text>}
                          <Text style={{ fontWeight:'bold', fontStyle:'italic'}}>(</Text>
                          {LoginManager.getInstance().loggedInOwner.id!=-1 && <Text style={{ fontWeight:'bold', fontStyle:'italic'}}>email: {LoginManager.getInstance().loggedInOwner.email})</Text>}
                          {LoginManager.getInstance().loggedInMusician.id!=-1 && <Text style={{ fontWeight:'bold', fontStyle:'italic'}}>email: {LoginManager.getInstance().loggedInMusician.email})</Text>}
                          {LoginManager.getInstance().loggedInTennisPlayer.id!=-1 && <Text style={{ fontWeight:'bold', fontStyle:'italic'}}>email: {LoginManager.getInstance().loggedInTennisPlayer.email})</Text>}
                          ,{i18n.t('promoteHandyBookerToOwnerPartTwo')}
                      </Text>
                    </View>
              </ScrollView>

              <Button icon={({ color, size }) => (<MaterialCommunityIcons name="email" color={color} size={size} />)} mode="contained" onPress={promoteHandyBookerToOwner} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{display: selectedOwner.active ? 'none' : 'flex'}} disabled={snackBarVisible==true ? true : false}>{i18n.t('promoteHandyBookerToOwnerBtn')}</Button>
              
              {(curLoggedInOwner.id!=-1 || curLoggedInMusician.id!=-1 || curLoggedInTennisPlayer.id!=-1) && visibleCalendarModal==false && snackBarVisible==false &&
                <Button icon={({ color, size }) => (<MaterialCommunityIcons name="calendar" color={color} size={size} />)} mode="contained" disabled={makeReservationBtnDisabled} onPress={showCalendarModal} labelStyle={styles.iconButton} contentStyle={styles.iconButtonContent} style={{display: selectedOwner.active ? 'flex' : 'none'}}>{i18n.t('makeReservation')}</Button>}
              
              <Snackbar
                style={styles.snackbarUpper}
                visible={snackBarVisible}
                onDismiss={onDismissSnackBar}>
                {snackBarText}
              </Snackbar>
              {MyAlertForWeb!=null && 
                <MyAlertForWeb
                  show={alertForWebVisible}
                  showProgress={false}
                  title={i18n.t('caution')}
                  message={alertForWebText}
                  closeOnTouchOutside={false}
                  closeOnHardwareBackPress={false}
                  showConfirmButton={true}
                  confirmText="OK"
                  confirmButtonColor="#DD6B55"
                  onCancelPressed={() => {
                    setAlertForWebVisible(false);
                  }}
                  onConfirmPressed={() => {
                    setAlertForWebVisible(false);
                  }}  
                />
              }
              <AdBanner/>
            </View>
            <RightColumnWeb/>
          </View>
        
      </View>
    );
};

/* Gia Carousel "react-native-reanimated-carousel" https://www.npmjs.com/package/react-native-reanimated-carousel?activeTab=readme    example: https://github.com/dohooo/react-native-reanimated-carousel/blob/main/example/app/src/pages/blur-parallax/index.tsx

interface ItemProps {
  source: ImageSourcePropType
  animationValue: Animated.SharedValue<number>
}
const CustomItem: React.FC<ItemProps> = ({ source, animationValue }) => {
  const maskStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animationValue.value,
      [-1, 0, 1],
      [1, 0, 1],
    );
 
    return {
      opacity,
    };
  }, [animationValue]);
 
  return (
    <View
      style={{
        flex: 1,
        borderRadius: 10,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        source={source}
        resizeMode={"contain"}
        style={{ width: "80%", height: "80%" }}
      />
      <BlurView
        intensity={50}
        pointerEvents="none"
        style={[StyleSheet.absoluteFill, maskStyle]}
      />
    </View>
  );
};*/

export default SelectedOwnerSummaryView;