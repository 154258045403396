export default {
    lookingFor: "I'm looking for",
    category: "Category",
    studioCategory: "Rehearsal/recording studios",
    tennisCategory: "Tennis courts",
    studiosDomainTitle: "Rehearsal/recording studio",
    tennisDomainTitle: "Tennis court",

    youHaveAnotherReservationAtThisTime: "The reservation cannot be completed because you have another reservation at this time slot!",
    internalError: "An internal error occurred! Please restart HandyBooker and try again.",

    pleaseCompleteEmail: "Please complete your email!",
    pleaseCompletePassword: "Please complete your password!",
    pleaseCompleteValidEmail: "This email is not valid!",

    reminderTitle: "HandyBooker - You need to be at the studio in 2 hours!",

    back: "Back",
    login: "LOGIN",
    browse: "Browse",
    browseStudios: "BROWSE STUDIOS",
    findStudio: "Find studio",
    findTennisCourt: "Find tennis court",
    studios: "Studios",
    tennisCourts: "Tennis courts",
    loading: "Loading...",
    forgotPassword: "I forgot my password",
    wrongEmailPassword: "Wrong email/password!",
    studioSummary: "Studio summary",
    myLocation: "My Location",
    search: "Search",
    musicianControlPanel: "Musician's control panel",
    studioInfo: "Studio information",
    tennisCourtInfo: "Tennis court information",
    makeReservation: "MAKE A RESERVATION",
    ownerNotYetRegistered: "This studio owner is not yet registered and, thus, no reservations are allowed through HandyBooker.",
    promoteHandyBookerToOwnerPartOne: "By clicking the button below, you can suggest HandyBooker to the studio owner (an email will be sent automatically saying that ",
    promoteHandyBookerToOwnerPartTwo: " suggests you to use HandyBooker).",
    promoteHandyBookerToOwnerBtn: "Suggest HandyBooker",
    promotionSuccessful: "You have promoted HandyBooker to this owner successfully! Thank you for the support!",
    youHaveAlreadyPromotedThisOwner: "You have already promoted HandyBooker to this owner!",
    musician: "Musician",
    player: "Player",
    bandCapital: "Band",
    cancelReservation: "Cancel reseravation",
    reservationCancelled: "The reservation has been cancelled and the musician has been informed via email.",
    newReservation: "New reservation",
    reservedByMe: "(Reserved by me)",

    registrationIsNeeded: "Registration is needed",

    noGPSAllowed: "Permission to access location was denied! Thus, the application cannot function properly!",
    
    studioOwnerInfo: "Owner info",
    tennisCourtOwnerInfo: "Owner info",
    companyInfo: "Company info",
    musicianDetails: "Musician details",
    playerDetails: "Player details",

    mondayTitle: "Monday",
    tuesdayTitle: "Tuesday",
    wednesdayTitle: "Wednesday",
    thursdayTitle: "Thursday",
    fridayTitle: "Friday",
    saturdayTitle: "Saturday",
    sundayTitle: "Sunday",
    januaryTitle: "January",
    februaryTitle: "February",
    marchTitle: "March",
    aprilTitle: "April",
    mayTitle: "May",
    juneTitle: "June",
    julyTitle: "July",
    augustTitle: "August",
    septemberTitle: "September",
    octoberTitle: "October",
    novemberTitle: "November",
    decemberTitle: "December",

    musicianProfile: "Musician's profile",
    tennisPlayerProfile: "Tennis player's profile",
    myProfile: "My profile",
    settings: "Settings",
    logout: "Logout",    
    name: "Name",
    surname: "Surname",
    telephone: "Telephone",
    password: "Password",
    passwordAgain: "Password (again)",
    band: "band: ",
    bandName: "Band name",
    save: "SAVE",
    profileUpdated: "Profile updated!",
    coordinatesUpdated: "Coordinates updated! Please restart HandyBooker to update the pin on the map.",
    coordinatesUpdatedWeb: "Coordinates updated! Please reload HandyBooker in your web browser to update the pin on the map.",
    passwordsDoNotMatch: "Passwords do not match!",
    studioOwnerProfile: "Studio owner's profile",
    tennisCourtOwnerProfile: "Tennis court(s) owner's profile",
    verifyAddress: "VERIFY ADDRESS",
    addressVerified: "Your address has been verified!",

    accountSettings: "Account settings",
    receiveEmailNotifications: "Receive email notifications",
    yesEmailNotifications: "From now on you will be notified also by email for new reservations",
    noEmailNotifications: "From now on you will not be notified by email for new reservations",
    deleteAccount: "DELETE ACCOUNT",
    accountDeleted: "Your account has been deleted!",

    addARoom: "Add a room",
    roomName: "Room name",
    courtName: "Court name",
    address: "Address",
    website: "Website",
    currency: "Currency",
    day: "Day",
    from: "From",
    to: "To",
    workingHours: "Working hours",
    workingHoursFrom: "Working hours (from)",
    workingHoursTo: "Working hours (to)",
    totalCost: "Cost",
    cost: "Cost (per hour)",
    costForGuests: "Cost (for non club members)",
    rehearsalCost: "Rehearsal cost (per hour)",
    recordingCost: "Recording cost (per hour)",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    open: "Open",
    closed: "Closed",
    availableRooms: "Available rooms",
    details: "Details",
    equipment: "Room information/Equipment",
    space: "Dimensions (m2)",
    drums: "Drums",
    guitarAmplifiers: "Guitar amplifiers",
    bassAmplifiers: "Bass amplifiers",
    microphones: "Microphones",
    console: "Console",
    speakers: "Speakers",
    guitars: "Guitars",
    basses: "Basses",
    pianosKeyboards: "Piano/keyboards",
    otherEquipment: "Other",
    selectDateTime: "Select date/time",
    free: "Available",
    reserved: "Not available",
    reservedByYou: "Reserved by you",

    manageRooms: "Manage Rooms",
    manageCourts: "Manage Courts",
    addFirstRoom: "Add a room",
    addFirstCourt: "Add a court",
    editRoomInfo: "Edit room info",
    editCourtInfo: "Edit court info",
    selectRoom: "Select room",
    selectCourt: "Select court",
    freeCost: "Free",
    type: "Type",
    acrylic: "Acrylic",
    artificialClay: "Artificial clay",
    artificialGrass: "Artificial grass",
    ashpalt: "Asphalt",
    carpet: "Carpet",
    clay: "Clay",
    concrete: "Concrete",
    grass: "Grass",
    other: "Other",
    differentCurrencies: "All your rooms must accept payments in the same currency ",
    pleaseCompleteMondayStartHour: "Please complete Monday start hour!",
    pleaseCompleteMondayStopHour: "Please complete Monday stop hour!",
    pleaseCompleteTuesdayStartHour: "Please complete Tuesday start hour!",
    pleaseCompleteTuesdayStopHour: "Please complete Tuesday stop hour!",
    pleaseCompleteWednesdayStartHour: "Please complete Wednesday start hour!",
    pleaseCompleteWednesdayStopHour: "Please complete Wednesay stop hour!",
    pleaseCompleteThursdayStartHour: "Please complete Thursday start hour!",
    pleaseCompleteThursdayStopHour: "Please complete Thursday stop hour!",
    pleaseCompleteFridayStartHour: "Please complete Friday start hour!",
    pleaseCompleteFridayStopHour: "Please complete Friday stop hour!",
    pleaseCompleteSaturdayStartHour: "Please complete Saturday start hour!",
    pleaseCompleteSaturdayStopHour: "Please complete Saturday stop hour!",
    pleaseCompleteSundayStartHour: "Please complete Sunday start hour!",
    pleaseCompleteSundayStopHour: "Please complete Sunday stop hour!",
    areYouSureThatRehearsalOnMondayIsFree: "Are you sure that rehearsal on Monday is free?",
    areYouSureThatRecordingOnMondayIsFree: "Are you sure that recording on Monday is free?",
    areYouSureThatRehearsalOnTuesdayIsFree: "Are you sure that rehearsal on Tuesday is free?",
    areYouSureThatRecordingOnTuesdayIsFree: "Are you sure that recording on Tuesday is free?",
    areYouSureThatRehearsalOnWednesdayIsFree: "Are you sure that rehearsal on Wednesday is free?",
    areYouSureThatRecordingOnWednesdayIsFree: "Are you sure that recording on Wednesday is free?",
    areYouSureThatRehearsalOnThursayIsFree: "Are you sure that rehearsal on Thursday is free?",
    areYouSureThatRecordingOnThursdayIsFree: "Are you sure that recording on Thursday is free?",
    areYouSureThatRehearsalOnFridayIsFree: "Are you sure that rehearsal on Friday is free?",
    areYouSureThatRecordingOnFridayIsFree: "Are you sure that recording on Friday is free?",
    areYouSureThatRehearsalOnSaturdayIsFree: "Are you sure that rehearsal on Saturday is free?",
    areYouSureThatRecordingOnSaturdayIsFree: "Are you sure that recording on Saturday is free?",
    areYouSureThatRehearsalOnSundayIsFree: "Are you sure that rehearsal on Sunday is free?",
    areYouSureThatRecordingOnSundayIsFree: "Are you sure that recording on Sunday is free?",
    pleaseEnterValidCostForMondayReh: "Please add a valid number (>=0) for rehearsal cost on Monday!",
    pleaseEnterValidCostForMondayRec: "Please add a valid number (>=0) for recording cost on Monday!",
    pleaseEnterValidCostForTuesdayReh: "Please add a valid number (>=0) for rehearsal cost on Tuesday!",
    pleaseEnterValidCostForTuesdayRec: "Please add a valid number (>=0) for recording cost on Tuesday!",
    pleaseEnterValidCostForWednesdayReh: "Please add a valid number (>=0) for rehearsal cost on Wednesday!",
    pleaseEnterValidCostForWednesdayRec: "Please add a valid number (>=0) for recording cost on Wednesday!",
    pleaseEnterValidCostForThursdayReh: "Please add a valid number (>=0) for rehearsal cost on Thursday!",
    pleaseEnterValidCostForThursdayRec: "Please add a valid number (>=0) for recording cost on Thursday!",
    pleaseEnterValidCostForFridayReh: "Please add a valid number (>=0) for rehearsal cost on Friday!",
    pleaseEnterValidCostForFridayRec: "Please add a valid number (>=0) for recording cost on Friday!",
    pleaseEnterValidCostForSaturdayReh: "Please add a valid number (>=0) for rehearsal cost on Saturday!",
    pleaseEnterValidCostForSaturdayRec: "Please add a valid number (>=0) for recording cost on Saturday!",
    pleaseEnterValidCostForSundayReh: "Please add a valid number (>=0) for rehearsal cost on Sunday!",
    pleaseEnterValidCostForSundayRec: "Please add a valid number (>=0) for recording cost on Sunday!",
    theRoomShouldBeAtLeastOneDayOpen: "The room should be open at least one day of the week!",

    areYouSureThatCostOnMondayIsFree: "Are you sure that charge on Monday is free?",
    areYouSureThatCostOnTuesdayIsFree: "Are you sure that charge on Tuesday is free?",
    areYouSureThatCostOnWednesdayIsFree: "Are you sure that charge on Wednesday is free?",
    areYouSureThatCostOnThursdayIsFree: "Are you sure that charge on Thursday is free?",
    areYouSureThatCostOnFridayIsFree: "Are you sure that charge on Friday is free?",
    areYouSureThatCostOnSaturdayIsFree: "Are you sure that charge on Saturday is free?",
    areYouSureThatCostOnSundayIsFree: "Are you sure that charge on Sunday is free?",
    pleaseEnterValidCostForMonday: "Please add a valid number (>=0) for cost on Monday!",
    pleaseEnterValidCostForTuesday: "Please add a valid number (>=0) for cost on Tuesday!",
    pleaseEnterValidCostForWednesday: "Please add a valid number (>=0) for cost on Wednesday!",
    pleaseEnterValidCostForThursday: "Please add a valid number (>=0) for cost on Thursday!",
    pleaseEnterValidCostForFriday: "Please add a valid number (>=0) for cost on Friday!",
    pleaseEnterValidCostForSaturday: "Please add a valid number (>=0) for cost on Saturday!",
    pleaseEnterValidCostForSunday: "Please add a valid number (>=0) for cost on Sunday!",
    theCourtShouldBeAtLeastOneDayOpen: "The court should be open at least one day of the week!",

    roomUpdated: "Room information has been updated!",
    courtUpdated: "Court information has been updated!",
    congratulations: "Congratulations!",
    roomAdded: "This new room has been added!",
    courtAdded: "This new court has been added!",
    add: "ADD",
    addRoom: "ADD ROOM",
    addCourt: "ADD COURT",
    delete: "DELETE",
    deleteRoom: "Room deletion!",
    deleteCourt: "Court deletion!",
    deleteRoomAreYouSure: "Are you sure that you want to delete this room?",
    deleteCourtAreYouSure: "Are you sure that you want to delete this court?",
    roomHasBeenDeleted: "The room has been deleted!",
    courtHasBeenDeleted: "The court has been deleted!",
    caution: "Caution!",

    photos: "Photos",
    infrastructure: "Infrastructure",
    rooms: "Rooms",
    customers: "Customers",
    players: "Musicians",
    myPlayers: "My musicians",
    tennisPlayers: "Tennis players",
    studios: "Studios",
    
    doYouWantToBlockMusician: "Do you want to block this musician?",
    doYouWantToUnblockMusician: "Do you want to unblock this musician?",
    blockMusicianMsg: "If you encounter problems with a musician (e.g. he cancels reservations continuously, damages equipment, etc.), you can block him. A blocked musician cannot make any reservation to your studio.",
    unblockMusicianMsg: "If you unblock him/her, he/she will be able to make reservations to your studio.",

    doYouWantToBlockPlayer: "Do you want to block this player?",
    doYouWantToUnblockPlayer: "Do you want to unblock this player?",
    blockPlayerMsg: "If you encounter problems with a tennis player (e.g. he cancels reservations continuously, damages equipment, etc.), you can block him. A blocked player cannot make any reservation to your tennis courts.",
    unblockPlayerMsg: "If you unblock him/her, he/she will be able to make reservations to your tennis courts.",

    clubMembersRequests: "Pending requests of new members",
    pendingSince: "Pending since",
    preferredMembership: "Membership plan",
    saysHeIsAlreadyMember: "The player claims that he/she is already a member of the club",
    confirmation: "Confirmation",
    confirmationMsgForClubRequestAcceptance: "You are going to accept this request and add this player to your club members",
    confirmationMsgForClubRequestRejection: "You are going to reject this request and avoid adding this player to your club members",
    clubRequestAccepted: "Request accepted!",
    clubRequestRejected: "Request rejected!",
    clubMembers: "Club members",
    clubMember: "Club member",
    guest: "Guest",
    memberSince: "Member since",
    membershipPlan: "Membership plan",
    membershipValidUntil: "Membership valid until",
    membershipPlanNotSelected: "No membership plan selected",
    membershipExpired: "Expired membership",
    nonMembersGuests: "Guests (non members)",
    show: "Show",
    onlyClubRequests: "Club requests",
    onlyClubMembers: "All Club members",
    onlyValidClubMembers: "Valid memberships",
    onlyExpiredMembers: "Expired memberships",
    onlyGuests: "Guests (non members)",
    allTheAbove: "All",
    noPendingClubRequests: "No pending requests",
    noClubMembers: "No club members",
    noGuests: "No guests",
    noMusicians: "No musicians",
    noCustomers: "No customers",
    youAreMemberOfThisTennisClub: "You are member of this tennis club",
    addNewClubMember: "Add new club member",
    clubMemberAddedSuccessfully: "Club member added successfully!",
    thereIsAnotherClubMemberWithThisEmail: "There is another club member with this email!",
    intruderMsg: "You do not have permissions for this!",
    youAreGoindToDeleteClubMember: "Do you want to proceed with the deletion of this club member?",
    clubMemberDeleted: "Club member deleted!",
    selectDate: "Date selection",

    musicianBlockedSuccessfully: "Musician blocked successfully!",
    musicianUnblockedSuccessfully: "Musician unblocked successfully!",
    playerBlockedSuccessfully: "Player blocked successfully!",
    playerUnblockedSuccessfully: "Player unblocked successfully!",
    blocked: "Blocked",
    messageToBlockedPlayer: "Please restart the application and retry!",

    managePhotos: "Manage Photos",
    uploadPhoto: "Upload a new photo",
    newPhoto: "New photo",
    fromLibrary: "Photo library",
    fromCamera: "Camera",
    myPhotos: "My photos",
    photoDeleted: "Photo deleted!",
    photoDesc: "Description",
    photoDescOptional: "Description (optional)",
    photoDescUpdated: "Photo description updated!",
    photoUploaded: "Photo uploaded successfully!",
    noUploadedPhotosYet: "You haven't uploaed any photos of your company yet!",

    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",

    januaryShort: "Jan",
    februaryShort: "Feb",
    marchShort: "Mar",
    aprilShort: "Apr",
    mayShort: "May",
    juneShort: "Jun",
    julyShort: "Jul",
    augustShort: "Aug",
    septemberShort: "Sep",
    octoberShort: "Oct",
    novemberShort: "Nov",
    decemberShort: "Dec",

    mondayShort: "Mon",
    tuesdayShort: "Tue",
    wednesdayShort: "Wed",
    thursdayShort: "Thu",
    fridayShort: "Fri",
    saturdayShort: "Sat",
    sundayShort: "Sun",

    today: "Today",

    selectType: "Select type",
    studio: "Studio",
    company: "Company",
    reservationDetails: "Reservation details",
    rehearsal: "Rehearsal",
    recording: "Recording",
    room: "Room",
    tennisCourt: "Tennis court",
    rehearsalRecording: "Rehearsal/Recording",
    date: "Date",
    hours: "Hours",
    commentsOptional: "Comments (optional)",
    comments: "Comments",
    continue: "Continue",
    completeReservation: "Complete reservation",
    selectTimeslots: "Please select the preferable timeslots",
    timeslotsAreNotSequential: "The selected timeslots must be sequential",
    reservationAddedSuccessfully: "Your reservation has been completed successfully!",
    pullDownToUpdate: "Pull down to update",
    invalidHours: "Please select timeslots that have not expired!",
    reservationMadeByYou: "Reservation made by you",

    myReservations: "My reservations",
    reservations: "Reservations",
    type: "Type",
    youHaveNoReservationForThisDate: "You have no reservations for this date",

    reportOwnerMenuTitle: "Report",
    reportOwner: "Report an owner",
    doYouHavePrlblemsWithOwner: "If you encounter problems with a studio owner (e.g. he/she cancelled your reservationwithout notifying you), please let us know by completing this form. This communication will be confidential. The studio owner will not be notified for this report!",
    ownerEmail: "Owner's email",
    reason: "Reason",
    submitReport: "Submit",
    noSuchOwnerExists: "No such owner exists! Please check if you wrote owner's email correctly!",
    reportSubmittedSuccessfully: "Report submitted successfully!",
    completeOwnerEmail: "Please complete the email of the owner!",
    completeReportReason: "Please complete the reason!",

    contactUsMenuTitle: "Contact",
    contactUs: "Contact us",
    yourComments: "Your comments",
    completeComments: "Please complete your comments",
    commentsSubmittedSuccessfully: "Thank you! You comments have been submitted successfully!",

    cancel: "Cancel",
    areYouSure: "Are you sure?",

    resetPassword: "Reset password",
    sendKey: "Send me a key",
    or: "OR",
    iVeAlreadyGottenKey: "If you've already gotten a key",
    newPassword: "New password",
    newPasswordAgain: "New password (again)",
    key: "Key",
    setNewPassword: "Change password",
    pleaseCompleteEmail: "Please complete your email!",
    pleaseCompleteNewPassword: "Please complete your new password!",
    pleaseCompleteNewPasswordAgain: "Please complete your new password again!",
    pleaseCompleteKey: "Please complete the key that you've received via email!",
    keySent: "A key for resetting your password has been sent to your email!",
    keyAlreadySent: "A key for resetting your password has been already sent to your email!",
    invalidEmailOrKey: "The email or key you provided is invalid!",
    passwordHasChanged: "Your password has been changed successfully!",

    notYetRegistered: "Not yet registered?",
    signUp: "Sign up",
    createAccount: "Create account",
    iAmMusician: "I am a musician",
    iAmStudioOwner: "I am a studio owner",
    iAmTennisPlayer: "I am a tennis player",
    iAmTennisCourtOwner: "I am a tennis court owner",
    reservationsOnlyForRehearsal: "Reservations for rehearsals only",
    reservationsOnlyForRecording: "Reservations for recordings only",
    reservationsForRehearsalAndRecording: "Reservations for both rehearsals and recordings",
    roomEditingNeeded: "You need to update the info of your rooms!",
    youNeedToSetCostForRehersal: "You need to set the rehearsal cost for all of your rooms.",
    youNeedToSetCostForRecording: "You need to set the recording cost for all of your rooms.",
    defineRehearsalCost: "Define rehearsal cost",
    defineRecordingCost: "Define recording cost",
    youNeedToDefineAtLeastOneMembershipPlan: "You need to define at least one membership plan",
    defineMembershipPlans: "Define membership plans",
    youNeedToDefineTheCostPerHourForEachCourtForNonMembers: "For non club members you need to define the cost (per hour) for each court",
    defineTheCostPerHourForEachCourt: "Define cost (per hour)",
    membershipPlansNeeded: "Membership plans needed!",
    courtEditingNeeded: "You need to update the info of your courts!",
    onlyClubMembersCanReserve: "It's a private club - Reservations are allowed only to club members",
    clubMembersAndNonClubMembersCanReserve: "It's a private club - Reservations are allowed to both club members and non club members",
    onlyNonClubMembersCanReserve: "It's not a private club - Reservations are allowed to anyone",

    privateClub: "Private Tennis Club",
    options: "Options",
    joinClub: "Join the club",
    iAmAlreadyAMember: "I am already member",
    reserveAsGuest: "Make a reservation as guest",
    selectMembershipPlan: "Select membership plan",
    sendRequest: "SEND REQUEST",
    youHaveAlreadySendARequest: "You have already sent a request to this tennis club. You'll be notified when the tennis club will process the request.",
    requestSent: "The request has been sent! You'll be notified when the tennis club will process the request.",

    companyName: "Company name",
    postalAddress: "Postal address",
    city: "City",
    country: "Country",
    longitude: "Longitude",
    latitude: "Latitude",
    website: "Website",
    pleaseCompleteName: "Please complete your name!",
    pleaseCompleteSurname: "Please complete your surname!",
    pleaseCompleteEmail: "Please complete your email!",
    pleaseCompletePhone: "Please complete your phone!",
    pleaseCompletePassword: "Please complete your password!",
    pleaseCompletePasswordAgain: "Please complete your password again!",
    pleaseCompleteCompanyName: "Please complete the name of your company!",
    pleaseCompletePostalAddress: "Please complete your postal address!",
    pleaseCompleteCity: "Please complete your city!",
    pleaseCompleteCountry: "Please complete your country!",

    accountAddedSuccessfully: "Registration completed successfully!",
    thereIsAnotherAccountWithThisEmail: "Error: Another existing account uses this email!",

    addMembershipPlans: "Subscription plans for club members",
    membershipOptions: "Membership options",
    membershipTitle: "Membership title",
    membershipDescription: "Membership description",
    membershipDuration: "Membership duration",
    membershipCost: "Membership cost",
    whoCanReserve: "Who can make reservations?",
    membershipPackages: "Membership plans",
    pleaseCompleteAllFieldsForAllMemberships: "Please complete all the fields for all registration plans",
    membershipPackagesUpdated: "Membership plans have been updated!",
    atLeastOneMembershipNeeded: "As you support club members, you need to define at least one membership plan",

    accountVerification: "Account verification",
    insertKeySentToYourEmail: "Please insert the key you've received in your email inbox",
    checkAlsoSpam: "Caution: Check also the 'spam' folder",
    key: "Key",
    activateAccount: "Activate account",
    ifYouHaveNotReceivedKey: "If you haven't received any key, please press the button below",
    resendKey: "Resend key",
    aKeyWasSent: "A key was sent to your email inbox",
    accountActivated: "Congratulations! Your account has been activated!",
    invalidKey: "Invalid key!",
    iWantAKey: "I want a key for password reset",
    iHaveAKey: "I already have a key",

    internalError: "An internal error occured! Please try again!",
    youNeedToBeARegisteredUser: "Registration is needed to access this information"


};