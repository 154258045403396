import moment from "moment";
import Generic_ConstManager from "./Generic_ConstManager";
import GUIManager from "./GUIManager";
import LoginManager from "./LoginManager";
import UtilsManager from "./UtilsManager";

class Tennis_RestManager {

    private static instance: Tennis_RestManager;

    private constructor() {
        
    }

    public static getInstance(): Tennis_RestManager {
        if (!Tennis_RestManager.instance) {
          Tennis_RestManager.instance = new Tennis_RestManager();
        }

        return Tennis_RestManager.instance;
    }

    public initialize() {

    }

    public async login(tmpEncryptedToken: string) : Promise<RoleIDAndDomain>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/login', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              encryptedToken: tmpEncryptedToken
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            //alert("Role: " + responseJson.role + ", ID: " + responseJson.ID + ", verified: " + responseJson.verified);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addPlayer(tmpPlayer : Tennis_Player) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPlayer.id,
            name: tmpPlayer.name,
            surname: tmpPlayer.surname,
            telephone: tmpPlayer.telephone,
            email: tmpPlayer.email.toLowerCase(),
            password: tmpPlayer.password,
            last_login: tmpPlayer.last_login,
            language: tmpPlayer.language,
            email_notifications: tmpPlayer.email_notifications,
            registration_date: tmpPlayer.registration_date,
            verified: tmpPlayer.verified    //verified is when he succesfully adds the verification code sent by HandyBooker
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("addPlayer response:", responseStr);
          return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async getPlayerByEmail(tmpEmail: string) : Promise<Tennis_Player>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getPlayer', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: LoginManager.getInstance().username.toLowerCase(),
                password: LoginManager.getInstance().md5EncodedPassword,
                language: LoginManager.getInstance().language,
                serviceInput: {
                    email: tmpEmail
                }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });      
    }

    public async getPlayerByID(tmpPlayerID: number) : Promise<Tennis_Player> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getPlayerById', {
            method: 'POST',
            headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
                id: tmpPlayerID
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async getAllPlayersOfAnOwner(tmpOwnerID: number) : Promise<PlayerWithBlockingStatus> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllPlayersOfAnOwner', {
          method: 'POST',
          headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
              id: tmpOwnerID
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
          return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
    }

    public async editPlayer(tmpPlayer: Tennis_Player) : Promise<Tennis_Player> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editPlayer', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                id: tmpPlayer.id,
                name: tmpPlayer.name,
                surname: tmpPlayer.surname,
                telephone: tmpPlayer.telephone,
                email: tmpPlayer.email.toLowerCase(),
                password: tmpPlayer.password,
                last_login: tmpPlayer.last_login,
                language: tmpPlayer.language,
                email_notifications: tmpPlayer.email_notifications,
                registration_date: tmpPlayer.registration_date,
                verified: tmpPlayer.verified
              }
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async deletePlayer(tmpEmail : string) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deletePlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            email: tmpEmail.toLowerCase()
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("deletePlayer response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async addOwner(tmpOwner : Owner) : Promise<RoleIDAndDomain> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addOwner', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpOwner.id,
            name: tmpOwner.name,
            surname: tmpOwner.surname,
            telephone: tmpOwner.telephone,
            email: tmpOwner.email.toLowerCase(),
            password: tmpOwner.password,
            company_name: tmpOwner.company_name,
            postal_address: tmpOwner.postal_address,
            city: tmpOwner.city,
            country: tmpOwner.country,
            longitude: tmpOwner.longitude,
            latitude: tmpOwner.latitude,
            website: tmpOwner.website,
            last_login: tmpOwner.last_login,
            language: tmpOwner.language,
            email_notifications: tmpOwner.email_notifications,
            registration_date: tmpOwner.registration_date,
            verified: tmpOwner.verified,   //verified is when he succesfully adds the verification code sent by HandyBooker
            active: tmpOwner.active,       //when active==false, this means that I manually created the user
            info_source: tmpOwner.info_source
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }
    
      public async getOwner(tmpEmail: string) : Promise<Owner>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              email: tmpEmail.toLowerCase()
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
      }

      public async getOwnerById(tmpOwnerId: number) : Promise<Owner>  {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getOwnerById', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerId
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
          console.log("getOwnerById response:", responseJson);
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
      }

      public async editOwner(tmpOwner : Owner) : Promise<Owner> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwner.id,
              name: tmpOwner.name,
              surname: tmpOwner.surname,
              telephone: tmpOwner.telephone,
              email: tmpOwner.email.toLowerCase(),
              password: tmpOwner.password,
              company_name: tmpOwner.company_name,
              postal_address: tmpOwner.postal_address,
              city: tmpOwner.city,
              country: tmpOwner.country,
              longitude: tmpOwner.longitude,
              latitude: tmpOwner.latitude,
              website: tmpOwner.website,
              last_login: tmpOwner.last_login,
              language: tmpOwner.language,
              email_notifications: tmpOwner.email_notifications,
              registration_date: tmpOwner.registration_date,
              verified: tmpOwner.verified,    //verified is when he succesfully adds the verification code sent by HandyBooker
              active: tmpOwner.active,        //when active==false, this means that I manually created the user
              info_source: tmpOwner.info_source
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
          console.error(error);
        });
      }

      public async deleteOwner(tmpEmail : string) : Promise<string | void> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              email: tmpEmail.toLowerCase()
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("deleteOwner response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
      }
    
      public async getAllOwners() : Promise<Array<Owner>> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllOwners', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
      }

    public async getCourt(tmpCourtID: number) : Promise<Tennis_Court> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getCourt', {
            method: 'POST',
            headers: {
                Accept: 'application/json; charset=UTF-8',
                        'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: LoginManager.getInstance().username.toLowerCase(),
                password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
                id: tmpCourtID
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async addCourt(tmpCourt : Tennis_Court) : Promise<Tennis_Court> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addCourt', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpCourt.id,
            owned_by: tmpCourt.owned_by,
            name: tmpCourt.name,
            type: tmpCourt.type,
            currency: tmpCourt.currency,
            monday_cost_per_hour: tmpCourt.monday_cost_per_hour,
            tuesday_cost_per_hour: tmpCourt.tuesday_cost_per_hour,
            wednesday_cost_per_hour: tmpCourt.wednesday_cost_per_hour,
            thursday_cost_per_hour: tmpCourt.thursday_cost_per_hour,
            friday_cost_per_hour: tmpCourt.friday_cost_per_hour,
            saturday_cost_per_hour: tmpCourt.saturday_cost_per_hour,
            sunday_cost_per_hour: tmpCourt.sunday_cost_per_hour,
            monday_working_hours_start: tmpCourt.monday_working_hours_start,
            tuesday_working_hours_start: tmpCourt.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpCourt.wednesday_working_hours_start,
            thursday_working_hours_start: tmpCourt.thursday_working_hours_start,
            friday_working_hours_start: tmpCourt.friday_working_hours_start,
            saturday_working_hours_start: tmpCourt.saturday_working_hours_start,
            sunday_working_hours_start: tmpCourt.sunday_working_hours_start,
            monday_working_hours_stop: tmpCourt.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpCourt.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpCourt.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpCourt.thursday_working_hours_stop,
            friday_working_hours_stop: tmpCourt.friday_working_hours_stop,
            saturday_working_hours_stop: tmpCourt.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpCourt.sunday_working_hours_stop
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async editCourt(tmpCourt : Tennis_Court) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editCourt', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpCourt.id,
            owned_by: tmpCourt.owned_by,
            name: tmpCourt.name,
            type: tmpCourt.type,
            currency: tmpCourt.currency,
            monday_cost_per_hour: tmpCourt.monday_cost_per_hour,
            tuesday_cost_per_hour: tmpCourt.tuesday_cost_per_hour,
            wednesday_cost_per_hour: tmpCourt.wednesday_cost_per_hour,
            thursday_cost_per_hour: tmpCourt.thursday_cost_per_hour,
            friday_cost_per_hour: tmpCourt.friday_cost_per_hour,
            saturday_cost_per_hour: tmpCourt.saturday_cost_per_hour,
            sunday_cost_per_hour: tmpCourt.sunday_cost_per_hour,
            monday_working_hours_start: tmpCourt.monday_working_hours_start,
            tuesday_working_hours_start: tmpCourt.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpCourt.wednesday_working_hours_start,
            thursday_working_hours_start: tmpCourt.thursday_working_hours_start,
            friday_working_hours_start: tmpCourt.friday_working_hours_start,
            saturday_working_hours_start: tmpCourt.saturday_working_hours_start,
            sunday_working_hours_start: tmpCourt.sunday_working_hours_start,
            monday_working_hours_stop: tmpCourt.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpCourt.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpCourt.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpCourt.thursday_working_hours_stop,
            friday_working_hours_stop: tmpCourt.friday_working_hours_stop,
            saturday_working_hours_stop: tmpCourt.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpCourt.sunday_working_hours_stop
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async editCurrencyOfAllCourtsOfCurOwner(tmpCourt : Tennis_Court) : Promise<Array<Tennis_Court>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editCurrencyOfAllCourtsOfCurOwner', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpCourt.id,
            owned_by: tmpCourt.owned_by,
            name: tmpCourt.name,
            type: tmpCourt.type,
            currency: tmpCourt.currency,
            monday_cost_per_hour: tmpCourt.monday_cost_per_hour,
            tuesday_cost_per_hour: tmpCourt.tuesday_cost_per_hour,
            wednesday_cost_per_hour: tmpCourt.wednesday_cost_per_hour,
            thursday_cost_per_hour: tmpCourt.thursday_cost_per_hour,
            friday_cost_per_hour: tmpCourt.friday_cost_per_hour,
            saturday_cost_per_hour: tmpCourt.saturday_cost_per_hour,
            sunday_cost_per_hour: tmpCourt.sunday_cost_per_hour,
            monday_working_hours_start: tmpCourt.monday_working_hours_start,
            tuesday_working_hours_start: tmpCourt.tuesday_working_hours_start,
            wednesday_working_hours_start: tmpCourt.wednesday_working_hours_start,
            thursday_working_hours_start: tmpCourt.thursday_working_hours_start,
            friday_working_hours_start: tmpCourt.friday_working_hours_start,
            saturday_working_hours_start: tmpCourt.saturday_working_hours_start,
            sunday_working_hours_start: tmpCourt.sunday_working_hours_start,
            monday_working_hours_stop: tmpCourt.monday_working_hours_stop,
            tuesday_working_hours_stop: tmpCourt.tuesday_working_hours_stop,
            wednesday_working_hours_stop: tmpCourt.wednesday_working_hours_stop,
            thursday_working_hours_stop: tmpCourt.thursday_working_hours_stop,
            friday_working_hours_stop: tmpCourt.friday_working_hours_stop,
            saturday_working_hours_stop: tmpCourt.saturday_working_hours_stop,
            sunday_working_hours_stop: tmpCourt.sunday_working_hours_stop
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async deleteCourt(tmpCourtId : number) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteCourt', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpCourtId
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }
    
    public async getAllCourtsOfOwner(tmpOwnerId : number) : Promise<Array<Tennis_Court>> {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getOwnerCourts', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpOwnerId
              }
            }),
          })
          .then(response => response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
      }

    public async addReservation(tmpReservation: Tennis_Reservation) {
      /*var byWhomId = -1;      
      if(LoginManager.getInstance().role == Generic_ConstManager.getInstance().PLAYER_ROLE) {
        if(LoginManager.getInstance().domain == LoginManager.getInstance().STUDIO) {
          byWhomId = LoginManager.getInstance().loggedInMusician.id;
        }
        else if(LoginManager.getInstance().domain == LoginManager.getInstance().TENNIS) {
          byWhomId = LoginManager.getInstance().loggedInTennisPlayer.id;
        }
      }
      else if(LoginManager.getInstance().role == Generic_ConstManager.getInstance().OWNER_ROLE) {
        byWhomId = LoginManager.getInstance().loggedInOwner.id;
        //web-service side: RESERVATION_MADE_BY_THE_OWNER_OF_THE_COURT = -1000 
      }*/
      
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addReservation', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                for_court: tmpReservation.for_court,
                by_user_email: LoginManager.getInstance().username.toLowerCase(),
                reservation_date: tmpReservation.reservation_date,
                reservation_hour_start: tmpReservation.reservation_hour_start,
                reservation_hour_stop: tmpReservation.reservation_hour_stop,
                comments: tmpReservation.comments
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addReservation response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    /*public async getReservation(tmpReservationID : number) : Promise<Reservation> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/studio_getReservation', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpReservationID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }*/

    public async getAllReservationsByUserEmail(tmpPlayerEmail : string) : Promise<Array<Tennis_Reservation>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByUserEmail', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                email: tmpPlayerEmail.toLowerCase()
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });      
    }

    public async getAllReservationsByUserEmail_forReactNativeCalendarsAgenda(tmpPlayerEmail : string) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByUserEmail_forReactNativeCalendarsAgenda', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              email: tmpPlayerEmail.toLowerCase()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByPlayerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID_forReactNativeCalendarsAgenda(tmpOwnerID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByOwnerID_forReactNativeCalendarsAgenda', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate(tmpOwnerID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              owner_id: tmpOwnerID,
              from_date: UtilsManager.getInstance().getFirstDayOfPreviousMonth(),
              to_date: UtilsManager.getInstance().getLastDayOfNextMonth()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          /*console.log("getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate - Start");
          console.log("  LoginManager.getInstance().username.toLowerCase(): ", LoginManager.getInstance().username.toLowerCase());
          console.log("  LoginManager.getInstance().md5EncodedPassword: ", LoginManager.getInstance().md5EncodedPassword);
          console.log("  LoginManager.getInstance().language: ", LoginManager.getInstance().language);
          console.log("  UtilsManager.getInstance().getFirstDayOfPreviousMonth(): ", UtilsManager.getInstance().getFirstDayOfPreviousMonth());
          console.log("  UtilsManager.getInstance().getLastDayOfNextMonth(): ", UtilsManager.getInstance().getLastDayOfNextMonth());
          console.log("getAllReservationsByOwnerID_forReactNativeCalendarsAgenda_fromDateToDate - End");*/
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda START ***");
          //console.log(JSON.stringify(responseJson, null, 2));
          //console.log("*** getAllReservationsByOwnerID_forReactNativeCalendarsAgenda END ***");
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByOwnerID(tmpOwnerID : number) : Promise<Array<Tennis_Reservation>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByOwnerID', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpOwnerID
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async getAllReservationsByCourtID(tmpCourtId : number) : Promise<Array<Tennis_Reservation>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByCourtID', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpCourtId
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            //console.log("studio_getAllReservationsByRoomID RESULT: ", responseJson);
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });      
    }

    public async getAllReservationsByCourtID_fromDateToDate(tmpCourtId : number) : Promise<Array<Tennis_Reservation>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllReservationsByCourtID_fromDateToDate', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              court_id: tmpCourtId,
              from_date: moment().format('DD/MM/YYYY').toString(), //today
              to_date: UtilsManager.getInstance().getLastDayOfNextMonth()
            }
          }),
        })
        .then(async response => await response.json())
        .then(responseJson => {
          //console.log("studio_getAllReservationsByRoomID RESULT: ", responseJson);
          return responseJson;
        })
        .catch(error => {
          console.error(error);
        });      
    }

    public async cancelReservation(tmpReservationID : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/cancelReservation', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            language: LoginManager.getInstance().language,
            serviceInput: {
              id: tmpReservationID
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          //console.log("cancelReservation response:", responseStr);    
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async getOwnerPics(tmpOwnerId : number) : Promise<Array<PicBase64>> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getOwnerPics', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              language: LoginManager.getInstance().language,
              serviceInput: {
                id: tmpOwnerId
              }
            }),
          })
          .then(async response => await response.json())
          .then(responseJson => {
            return responseJson;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async uploadPhoto(tmpPhoto : PicBase64) : Promise<Array<PicBase64>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/uploadPhoto', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          language: LoginManager.getInstance().language,
          serviceInput: {
            id: tmpPhoto.id,
            owner_id: tmpPhoto.owner_id,
            base64_encoded_str: tmpPhoto.base64_encoded_str,   //there is no corresponding field in DB
            pic_description: tmpPhoto.pic_description,
            local_filepath_on_server: tmpPhoto.local_filepath_on_server,
            pic_url: tmpPhoto.pic_url
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("uploadPhoto response: " + JSON.stringify(responseJson));
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async deleteOwnerPic(tmpPhotoId: number) : Promise<Array<PicBase64>> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteOwnerPic', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPhotoId
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("deleteOwnerPic response:", responseJson);
        return responseJson;
      })
      .catch(error => {
        console.error(error);
      });      
    }

    public async editOwnerPicDescription(tmpPhotoId: number, tmpNewPhotoDesc: string) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editOwnerPicDescription', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: tmpPhotoId,
            owner_id: LoginManager.getInstance().ID,
            pic_description: tmpNewPhotoDesc
          }
        }),
      })
      .then(async response => await response.text())
      .then(responseStr => {
        //console.log("editOwnerPicDescription response:", responseStr);

        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });      
    }

    public async verifyAccount(tmpKey : string) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/verifyAccount', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            id: -1,
            key_string: tmpKey,
            submitted_by_email: LoginManager.getInstance().username.toLowerCase(),
            submit_date: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("verifyAccount response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async resendVerificationKey() : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/resendVerificationKey', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          language: LoginManager.getInstance().language,
          serviceInput: {          
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        console.log("resendVerificationKey response:", responseStr);
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async requestKeyForPasswordReset(tmpSubmittedByEmail : string, tmpLangNum : number) {
        return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/requestKeyForPasswordReset', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                submitted_by_email: tmpSubmittedByEmail.toLowerCase(),
                emailLanguage: tmpLangNum
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("requestKeyForPasswordReset response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          }
        );
    }
    
    public async updatePasswordWithKey(tmpEmail : string, tmpNewPasswordMD5Encoded : string, tmpKey : string) {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/updatePasswordWithKey', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: tmpEmail,
              password: "itIsIgnored",
              serviceInput: {
                newwPassword: tmpNewPasswordMD5Encoded,
                key: tmpKey
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("updatePasswordWithKey response:", responseStr);    
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addComment(tmpComments : string) : Promise<string | void> {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addComment', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                submitted_by_email: LoginManager.getInstance().username.toLowerCase(),
                my_comment: tmpComments
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addComment response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async addReportForOwner(tmpOwnerEmail : string, tmpReportReason : string) {
        return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addReportForOwner', {
            method: 'POST',
            headers: {
              Accept: 'application/json; charset=UTF-8',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
              serviceInput: {
                player_email: LoginManager.getInstance().username.toLowerCase(),
                owner_email: tmpOwnerEmail.toLowerCase().toLowerCase(),
                report: tmpReportReason
              }
            }),
          })
          .then(response => response.text())
          .then(responseStr => {
            console.log("addReportForOwner response:", responseStr);
            return responseStr;
          })
          .catch(error => {
            console.error(error);
          });
    }

    public async blockPlayer(tmpBlockedPlayer : BlockedPlayer) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/blockPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            owner_id: tmpBlockedPlayer.owner_id,
            blocked_user_email: tmpBlockedPlayer.blocked_user_email.toLowerCase(),
            reason: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async unblockPlayer(tmpBlockedPlayer : BlockedPlayer) : Promise<string | void> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/unblockPlayer', {
        method: 'POST',
        headers: {
          Accept: 'application/json; charset=UTF-8',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: LoginManager.getInstance().username.toLowerCase(),
          password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
            owner_id: tmpBlockedPlayer.owner_id,
            blocked_user_email: tmpBlockedPlayer.blocked_user_email.toLowerCase(),
            reason: ""
          }
        }),
      })
      .then(response => response.text())
      .then(responseStr => {
        return responseStr;
      })
      .catch(error => {
        console.error(error);
      });
    }

    public async promoteHandyBookerToOwner(tmpOwnerId : number) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/promoteHandyBookerToOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              from_user_email: LoginManager.getInstance().username.toLowerCase(),
              to_owner_id: tmpOwnerId
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          //console.log("promoteHandyBookerToOwner response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    /*****************/
    /* PRIVATE CLUBS */
    /*****************/

    /*public async addPrivateClub(tmpOwnerId : number) : Promise<string | void> {
      console.log("kalw addPrivateClub me owner_id: ", tmpOwnerId);
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addPrivateClub', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: -1,
              owner_id: tmpOwnerId
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addPrivateClub response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }
    
    
    public async isPrivateClub(tmpOwnerId : number) : Promise<boolean | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/isPrivateClub', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: tmpOwnerId
            }
          }),
        })
        .then(response => {
          if (response.ok) {
            return response.text();
          }
          throw new Error('isPrivateClub failed!');
        })
        .then(responseText => {
          const promoteResult = responseText === 'true';
          console.log("isPrivateClub response: ", promoteResult);
          return promoteResult;
        })
        .catch(error => {
          console.error(error);
        });
    }*/
    
    /********************/
    /* OWNER EXTRA INFO */
    /********************/

    public async addOwnerExtraInfo(tmpOwnerExtraInfo : Tennis_OwnerExtraInfo) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addOwnerExtraInfo', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              allows_reservations_to_members: tmpOwnerExtraInfo.allows_reservations_to_members,
              allows_reservations_to_non_members: tmpOwnerExtraInfo.allows_reservations_to_non_members 
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addOwnerExtraInfo response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async editOwnerExtraInfo(tmpOwnerExtraInfo : Tennis_OwnerExtraInfo) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editOwnerExtraInfo', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              allows_reservations_to_members: tmpOwnerExtraInfo.allows_reservations_to_members,
              allows_reservations_to_non_members: tmpOwnerExtraInfo.allows_reservations_to_non_members 
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("editOwnerExtraInfo response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async getOwnerExtraInfo(tmpOwnerID: number) : Promise<Tennis_OwnerExtraInfo> {
      return fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getOwnerExtraInfo', {
          method: 'POST',
          headers: {
              Accept: 'application/json; charset=UTF-8',
                      'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              username: LoginManager.getInstance().username.toLowerCase(),
              password: LoginManager.getInstance().md5EncodedPassword,
          serviceInput: {
              id: tmpOwnerID
          }
        }),
      })
      .then(response => response.json())
      .then(responseJson => {
        console.log("getOwnerExtraInfo response:", responseJson);
        return responseJson;
      })
      .catch(error => {
          console.error(error);
      });
    }

    /**************************/
    /* ONWER CLUB MEMBERSHIPS */
    /**************************/

    public async addOwnerClubMembership(tmpOwnerMembership : Tennis_OwnerMembership) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addOwnerClubMembership', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              title: tmpOwnerMembership.title,
              description: tmpOwnerMembership.description,
              duration: tmpOwnerMembership.duration,
              cost: tmpOwnerMembership.cost
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addOwnerClubMembership response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async editOwnerClubMembership(tmpOwnerMembership : Tennis_OwnerMembership) {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editOwnerClubMembership', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID,
              title: tmpOwnerMembership.title,
              description: tmpOwnerMembership.description,
              duration: tmpOwnerMembership.duration,
              cost: tmpOwnerMembership.cost
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("editOwnerClubMembership response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async getAllClubMembershipsOfOwner(tmpOwnerId : number) : Promise<Array<Tennis_OwnerMembership>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllClubMembershipsOfOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwnerId
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("getAllClubMembershipsOfOwner response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async deleteOwnerClubMembership(tmpOwnerMembership : Tennis_OwnerMembership) : Promise<boolean> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteOwnerClubMembership', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwnerMembership.id,
              owner_id: tmpOwnerMembership.owner_id
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("deleteOwnerClubMembership response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async deleteAllOwnerClubMemberships() : Promise<boolean> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteAllOwnerClubMemberships', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: LoginManager.getInstance().ID
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("deleteAllOwnerClubMemberships response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    /****************/
    /* CLUB MEMBERS */
    /****************/
    
    public async getAllClubMembersOfOwner(tmpOwnerId : number) : Promise<Array<Tennis_ClubMemberWithGenericPlayerInfoAndMembership>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllClubMembersOfOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwnerId
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("getAllClubMembersOfOwner response: ", JSON.stringify(responseJson, null, 2));
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }

    public async addClubMember(tmpTennisClubMember : Tennis_ClubMember) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addClubMember', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {

              owner_id: tmpTennisClubMember.owner_id,
              user_email: tmpTennisClubMember.user_email.toLowerCase(),
              member_since_date: tmpTennisClubMember.member_since_date,
              membership_id: tmpTennisClubMember.membership_id,
              membership_valid_from_date: tmpTennisClubMember.membership_valid_from_date,
              membership_valid_to_date: tmpTennisClubMember.membership_valid_to_date,
              user_name_provided_by_owner: tmpTennisClubMember.user_name_provided_by_owner,
              user_surname_provided_by_owner: tmpTennisClubMember.user_surname_provided_by_owner,
              user_telephone_provided_by_owner: tmpTennisClubMember.user_telephone_provided_by_owner
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addClubMember response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async editClubMember(tmpTennisClubMember : Tennis_ClubMember) : Promise<string | void> {
      console.log("editClubMember -> tmpTennisClubMember:", tmpTennisClubMember);
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/editClubMember', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpTennisClubMember.id,
              owner_id: tmpTennisClubMember.owner_id,
              member_since_date: tmpTennisClubMember.member_since_date,
              membership_id: tmpTennisClubMember.membership_id,
              membership_valid_from_date: tmpTennisClubMember.membership_valid_from_date,
              membership_valid_to_date: tmpTennisClubMember.membership_valid_to_date,
              user_name_provided_by_owner: tmpTennisClubMember.user_name_provided_by_owner,
              user_surname_provided_by_owner: tmpTennisClubMember.user_surname_provided_by_owner,
              user_telephone_provided_by_owner: tmpTennisClubMember.user_telephone_provided_by_owner
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("editClubMember response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }
    
    public async deleteClubMember(tmpTennisClubMember : Tennis_ClubMember) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/deleteClubMember', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: tmpTennisClubMember.owner_id,
              user_email: tmpTennisClubMember.user_email.toLowerCase()
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("deleteClubMember response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    public async isClubMember(tmpTennisClubMember : Tennis_ClubMember) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/isClubMember', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              owner_id: tmpTennisClubMember.owner_id,
              user_email: tmpTennisClubMember.user_email.toLowerCase()
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("isClubMember response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

    /*****************/
    /* CLUB REQUESTS */
    /*****************/

    public async getAllClubRequestsOfOwner(tmpOwnerId : number) : Promise<Array<Tennis_ClubRequestWithGenericPlayerInfoAndMembership>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllClubRequestsOfOwner', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpOwnerId
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("getAllClubRequestsOfOwner response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }
    
    public async getAllClubRequestsOfPlayer(tmpTennisPlayer : Tennis_Player) : Promise<Array<Tennis_ClubRequest>> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/getAllClubRequestsOfPlayer', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              email: tmpTennisPlayer.email.toLowerCase()
            }
          }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log("getAllClubRequestsOfPlayer response: ", JSON.stringify(responseJson, null, 2))
            return responseJson;
        })
        .catch(error => {
            console.error(error);
        });
    }
    
    public async addClubRequest(tmpTennisClubRequest : Tennis_ClubRequest) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/addClubRequest', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              from_user_email: tmpTennisClubRequest.from_user_email.toLowerCase(),
              to_owner_id: tmpTennisClubRequest.to_owner_id,
              membership_id: tmpTennisClubRequest.membership_id,
              says_he_is_already_member: tmpTennisClubRequest.says_he_is_already_member
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("addClubRequest response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }
    
    public async acceptClubRequest(tmpTennisClubRequest : Tennis_ClubRequest) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/acceptClubRequest', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpTennisClubRequest.id,
              from_user_email: tmpTennisClubRequest.from_user_email.toLowerCase(),
              to_owner_id: tmpTennisClubRequest.to_owner_id,
              membership_id: tmpTennisClubRequest.membership_id
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("acceptClubRequest response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }
    
    public async rejectClubRequest(tmpTennisClubRequest : Tennis_ClubRequest) : Promise<string | void> {
      return await fetch(GUIManager.getInstance().ngrokURL + '/TeCReS_WS/tecres/rejectClubRequest', {
          method: 'POST',
          headers: {
            Accept: 'application/json; charset=UTF-8',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: LoginManager.getInstance().username.toLowerCase(),
            password: LoginManager.getInstance().md5EncodedPassword,
            serviceInput: {
              id: tmpTennisClubRequest.id,
              from_user_email: tmpTennisClubRequest.from_user_email.toLowerCase(),
              to_owner_id: tmpTennisClubRequest.to_owner_id,
              membership_id: tmpTennisClubRequest.membership_id
            }
          }),
        })
        .then(response => response.text())
        .then(responseStr => {
          console.log("rejectClubRequest response:", responseStr);
          return responseStr;
        })
        .catch(error => {
          console.error(error);
        });
    }

}

export default Tennis_RestManager;